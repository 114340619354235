import {Box, Button} from "@mui/material";
import {SET_SHOW_MODEL_INFO, useModelViewerDispatch, useModelViewerState} from "../App/ModelViewerContext";
import {Info} from "@mui/icons-material";

export const ToggleInfoButton = () => {
    const {showModelInfo} = useModelViewerState()

    const dispatch = useModelViewerDispatch();
    const handleModelInfoToggle = () => {
        dispatch({ type: SET_SHOW_MODEL_INFO, showModelInfo: !showModelInfo });
    }

    return (
        <Box
            sx={{
                position: 'absolute',
                left: 18,
                bottom: 18
            }}
        >
            <Button
                startIcon={<Info />}
                variant={"outlined"}
                onClick={handleModelInfoToggle}
                size={"large"}
            >
                {`${showModelInfo ? 'Skjul' : 'Vis'} beskrivelse`}
            </Button>
        </Box>
    )
}