import {OpenInNew} from '@mui/icons-material';
import {Link, Stack} from "@mui/material";

export const HotspotContentLink = ({url, text}) => {
    return (
        <Link
            href={url}
            target="_blank"
            rel="noreferrer"
            color={"inherit"}
        >
            <Stack
                direction={"row"}
                alignItems={"center"}
            >
                <OpenInNew sx={{ marginRight: '5px' }} />
                {text}
            </Stack>
        </Link>
    )
}