export const ModelTypes = Object.freeze({
  OBJECT: 'Object',
  IMAGE_SINGLE: 'ImageSingle',
  IMAGE_DOUBLE: 'ImageDouble',
});

export const ZoomDistance = Object.freeze({
  MAX: 1.5,
  MIN: 0.01,
});

