import {Link, Stack, Typography} from "@mui/material";

export const LicenseItem = ({label, url, value}) => {
    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={1}
        >
            <Typography>{label}:</Typography>
            <Link
                href={url}
                target="_blank"
                rel="noreferrer"
            >
                {value}
            </Link>
        </Stack>
    )
}