export const demo11 = {
    model: {
        type: 'Object',
        dmsId: '0136NvAPSgAB',
        rotation: { x: 0, y: 0, z: 0 },
    },
    license: {
        creatorName: '3dhdscan',
        creatorUrl: 'https://sketchfab.com/3dhdscan',
        licenseName: 'CC BY 4.0',
        licenseUrl: 'https://creativecommons.org/licenses/by/4.0/',
        sourceName: 'Statue of a hunter',
        sourceUrl:
            'https://sketchfab.com/3d-models/statue-of-a-hunter-f777e0a58bd84e7f945a9dd688eab27a',
    },
}