import {useTexture} from "@react-three/drei";
import {getDmsUrl} from "@ekultur/dms-url-generator";

export const ImagePlane = ({rotation, onClick, dmsId, url}) => {
    // url for backwardcompability
    const texture = useTexture(dmsId ? `https://ems.dimu.org/image/${dmsId}?dimension=2048` : url);

    return (
        <mesh
            rotation={rotation}
            onClick={onClick}
        >
            <planeGeometry
                attach="geometry"
                args={[texture.image.width / texture.image.height, 1]}
            />
            <meshStandardMaterial
                attach="material"
                map={texture}
            />
        </mesh>
    )
}