import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { EditGeneralSettings } from './EditGeneralSettings';
import { EditHotspots } from './EditHotspots';
import { EditModelInfo } from './EditModelInfo';
import { SettingsGroup } from './SettingsGroup';

const useStyles = createUseStyles({
  settingsBar: {
    minWidth: '387px',
    maxWidth: '387px',
    overflowY: 'auto',
    backgroundColor: '#F5F5F5',
    '& .slider': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 15,
      '& .MuiTypography-root': {
        fontSize: 14,
      },
      '& .MuiSlider-rail, .MuiSlider-track': {
        color: 'rgba(0, 0, 0, 0.4)',
      },
      '& .MuiSlider-thumb': {
        color: '#666666',
      },
      '& input': {
        height: 32,
        padding: '0 5px 0 10px',
        fontSize: 14,
      },
    },
  },
});

export const SettingsBar = ({ data, onChange }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState();

  const handleExpandChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.settingsBar}>
      <SettingsGroup
        title={'Innstillinger'}
        expanded={expanded === 'Innstillinger'}
        onExpandChange={handleExpandChange}
      >
        <EditGeneralSettings data={data} />
      </SettingsGroup>
      <SettingsGroup
        title={'Beskrivelse'}
        expanded={expanded === 'Beskrivelse'}
        onExpandChange={handleExpandChange}
      >
        <EditModelInfo data={data} />
      </SettingsGroup>
      <SettingsGroup
        title={'Infopunkter'}
        expanded={expanded === 'Infopunkter'}
        onExpandChange={handleExpandChange}
      >
        <EditHotspots data={data} onChange={onChange} />
      </SettingsGroup>
    </div>
  );
};
