export const demo1 = {
    group: {
        length: 5,
        index: 0,
        name: 'Fridtjof Nansen',
    },
    model: {
        type: 'Object',
        dmsId: '0136NRjSVqDC',
        rotation: { x: 0, y: -2.25, z: 0 },
        info: {
            closedInitially: true,
            title: 'Lorem Ipsum',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            links: [
                { text: 'Klikk her', url: 'http://www.vg.no' },
                { text: 'Klikk her også', url: 'http://www.dagbladet.no' },
            ],
            audio: {
                dmsId: '019EBtFo2gP1t',
                volume: 1,
            },
        },
    },
    hotspots: [
        {
            id: 1,
            order: 1,
            lineStart: {
                "x": 0.00596075404452688,
                "y": 0.127513120037174,
                "z": -0.037422891657477905
            },
            position: {
                "x": 0.020177534453272,
                "y": 0.12282379693344228,
                "z": -0.07125916514671321
            },
            faceNormal: {
                "x": 0.507097197379584,
                "y": 0.8451487412075374,
                "z": -0.16904448421964685
            },
            matrixWorld: {
                "elements": [
                    -0.0000062817363851861004,
                    0,
                    0.000007780732164531231,
                    0,
                    0.000007780732164531231,
                    4.490737589678958e-16,
                    0.0000062817363851861004,
                    0,
                    -3.4941225527862654e-16,
                    0.000010000000251457085,
                    -2.820962900405813e-16,
                    0,
                    0.00003641338987375731,
                    -0.1444630895694475,
                    -0.000012572492957850512,
                    1
                ]
            },
            rotation: { x: -0.4835766655166829, y: -0.7508600230098504, z: 0.4498475679754349 },
            title: 'Infopunkt 1',
            description:
                'Denne Adeliepingvinen ble fanget under Belgica-ekspedisjonen 1897-99 langs den Antarktiske halvøy. Det ble fanget mange pingviner under ekspedisjonen – både til mat og forskning. Amundsen lærte seg å skrike på en måte som tiltrakk pingvinene. Som en kuriositet forsøkte han også å sette en pingvin som trekkdyr foran en slede, uten stor suksess. Den utstoppede pingvinen mangler svømmehud mellom tærne og den har mistet høyre vinge, muligens i forbindelse med utstoppingen.',
            zoom: 0.3,
        },
        {
            id: 2,
            order: 2,
            lineStart: {
                "x": 0.047725019154981,
                "y": -0.05873418848457747,
                "z": 0.04554543550100663
            },
            position: {
                "x": 0.07315835083239594,
                "y": -0.04800010775116783,
                "z": 0.07018128642187488
            },
            matrixWorld: {
                "elements": [
                    -0.0000062817363851861004,
                    0,
                    0.000007780732164531231,
                    0,
                    0.000007780732164531231,
                    4.490737589678958e-16,
                    0.0000062817363851861004,
                    0,
                    -3.4941225527862654e-16,
                    0.000010000000251457085,
                    -2.820962900405813e-16,
                    0,
                    0.00003641338987375731,
                    -0.1444630895694475,
                    -0.000012572492957850512,
                    1
                ]
            },
            faceNormal: {
                "x": 0.363693168546375,
                "y": -0.7273926279472039,
                "z": 0.5819168703180538
            },
            rotation: { x: -0.6010419664865311, y: -0.6305595777093529, z: -0.4910633090356133 },
            title: 'Infopunkt 2',
            description: 'Infopunkt 2',
            zoom: 0.3,
        },
        {
            id: 3,
            order: 3,
            lineStart: {
                "x": -0.019947941644529153,
                "y": 0.05704529175909156,
                "z": 0.011204955695369664
            },
            position: {
                "x": -0.047577703028683874,
                "y": 0.04841964219673152,
                "z": 0.03425261187733381
            },
            matrixWorld: {
                "elements": [
                    -0.0000062817363851861004,
                    0,
                    0.000007780732164531231,
                    0,
                    0.000007780732164531231,
                    4.490737589678958e-16,
                    0.0000062817363851861004,
                    0,
                    -3.4941225527862654e-16,
                    0.000010000000251457085,
                    -2.820962900405813e-16,
                    0,
                    0.00003641338987375731,
                    -0.1444630895694475,
                    -0.000012572492957850512,
                    1
                ]
            },
            faceNormal: {
                "x": -0.7802747295916932,
                "y": -0.6242187039623806,
                "z": -0.039017380539139314
            },
            rotation: { x: 0.8127485328501485, y: -0.09726814523064845, z: -0.5744377514346706 },
            title: 'Infopunkt 3',
            links: [
                { text: 'Klikk her', url: 'http://www.vg.no' },
                { text: 'Klikk her også', url: 'http://www.dagbladet.no' },
            ],
            description:
                'Denne Adeliepingvinen ble fanget under Belgica-ekspedisjonen 1897-99 langs den Antarktiske halvøy. Det ble fanget mange pingviner under ekspedisjonen – både til mat og forskning. Amundsen lærte seg å skrike på en måte som tiltrakk pingvinene. Som en kuriositet forsøkte han også å sette en pingvin som trekkdyr foran en slede, uten stor suksess. Den utstoppede pingvinen mangler svømmehud mellom tærne og den har mistet høyre vinge, muligens i forbindelse med utstoppingen.',
            zoom: 0.5,
        },
    ],
}