import {Button} from '@mui/material';
import {Add, Close} from '@mui/icons-material';

import {
  SET_ADD_HOTSPOT_MODE,
  useModelViewerDispatch, useModelViewerProps,
  useModelViewerState,
  useModelViewerTranslation
} from '../App/ModelViewerContext';

export const AddHotspotButton = () => {
  const t = useModelViewerTranslation();
  const { addHotspotMode } = useModelViewerState();
  const dispatch = useModelViewerDispatch();
  const {adminMode} = useModelViewerProps();

  if(adminMode) {
    return (
        <>
          {!addHotspotMode && (
              <Button
                  sx={{
                    backgroundColor: '#1B8751',
                    position: 'absolute',
                    bottom: '20px',
                    right: '20px',
                    zIndex: '100000000',
                    color: 'white',
                    padding: '10px 16px 10px 12px',
                    borderRadius: '999px',
                    '&:hover': {
                      backgroundColor: '#076033',
                    },
                  }}
                  onClick={() => {
                    dispatch({ type: SET_ADD_HOTSPOT_MODE, addHotspotMode: true });
                  }}
              >
                <Add />
                {t('addHotspotButtonText', 'Legg til infopunkt')}
              </Button>
          )}
          {addHotspotMode && (
              <Button
                  sx={{
                    backgroundColor: '#1B8751',
                    position: 'absolute',
                    bottom: '20px',
                    right: '20px',
                    zIndex: '100000000',
                    color: 'white',
                    padding: '10px 16px 10px 12px',
                    '&:hover': {
                      backgroundColor: '#076033',
                    },
                  }}
                  onClick={() => {
                    dispatch({ type: SET_ADD_HOTSPOT_MODE, addHotspotMode: false });
                  }}
              >
                <Close />
                {t('closeAddHotspotButtonText', 'Ferdig å plassere')}
              </Button>
          )}
        </>
    )
  } else {
    return null
  }
}
