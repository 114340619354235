import { Euler } from 'three';
import { theme } from '@ekultur/vm-theme';
import { ThemeProvider } from 'react-jss';
import { Admin } from '../Admin/Admin';
import { Display } from '../Display/Display';
import { ModelViewerProvider } from './ModelViewerContext';
import { useSetupApp } from './useSetupApp';
import { useInitializeApp } from './useInitializeApp';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { isMobileDevice } from '../helpers/helpers';

export const adminTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    adminBlue: '#4484F1',
    adminGray: '#9E9E9E',
    primaryColor: '#1B8751',
  },
};

export const App = ({
  data,
  adminMode = false,
  onClose,
  onMediaSelect,
  onChange,
  onGroupItemChange,
  showToolbar = true,
  showAnnotations = true,
  showGizmo = false,
  enablePan = true,
  enableZoom = true,
}) => {
  const appReady = useSetupApp(adminMode);
  const isMobile = isMobileDevice();
  data.model.rotation = data.model.rotation ?? new Euler();

  useInitializeApp({
    showToolbar,
    showAnnotations,
    showGizmo,
    enablePan,
    enableZoom,
    showModelInfo: !isMobile && !data.model?.info?.closedInitially,
  });

  return (
    <ThemeProvider theme={adminTheme}>
      <DndProvider backend={HTML5Backend}>
        <ModelViewerProvider
          data={data}
          onMediaSelect={onMediaSelect}
          onChange={onChange}
          onGroupItemChange={onGroupItemChange}
          adminMode={adminMode}
          appReady={appReady}
        >
          {adminMode
            ? <Admin onClose={onClose} isMobile={isMobile} />
            : <Display onClose={onClose} isMobile={isMobile} />
          }
        </ModelViewerProvider>
      </DndProvider>
    </ThemeProvider>
  );
};
