import {useState} from "react";

export const useMouseHover = () => {
    const [hover, setHover] = useState(false)
    
    const toggleHover = event => {
        event.stopPropagation()
        setHover(true)
    }
    
    const untoggleHover = () => {
        setHover(false)
    }

    return {
        hover: hover,
        toggleHover: toggleHover,
        untoggleHover: untoggleHover
    }
}