import { createUseStyles } from 'react-jss';
import { SettingsBar } from '../SettingsBar/SettingsBar';
import { useModelViewerProps } from '../App/ModelViewerContext';
import { ModelViewer } from '../Canvas/ModelViewer';

const useStyles = createUseStyles({
  admin: {
    display: 'flex',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    position: 'relative',
    fontFamily:
      '"Akkurat", -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji',
  },
  adminCanvasWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  content: {
    display: 'flex',
    height: '100%',
  },
  emptyCanvas: {
    width: '100%',
    background: '#EEEEEE',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '250px',
  },
});

export const Admin = () => {
  const { data, onChange } = useModelViewerProps();
  const classes = useStyles();

  return (
    <div className={classes.admin}>
      {data && <ModelViewer data={data} />}
      <SettingsBar data={data} onChange={onChange} />
    </div>
  );
};
