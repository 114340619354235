import {ArrowBackIos, ArrowForwardIos} from '@mui/icons-material';
import {Box, IconButton, Typography} from "@mui/material";


export const Carousel = ({data, onChange}) => {
    const {length, index, name} = data;

    const handlePrev = () => {
        onChange(index > 0 ? index - 1 : length - 1);
    };

    const handleNext = () => {
        onChange(index < length - 1 ? index + 1 : 0);
    };

    return (
        <>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: 32
                }}
            >
                <IconButton
                    onClick={handlePrev}
                    color={"primary"}
                >
                    <ArrowBackIos
                        fontSize={"large"}
                    />
                </IconButton>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    right: 32
                }}
            >
                <IconButton
                    onClick={handleNext}
                    color={"primary"}
                >
                    <ArrowForwardIos
                        fontSize={"large"}
                    />
                </IconButton>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    top: '5%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                }}
            >
                <Typography
                    variant={"h4"}
                >
                    {`${name} ${index + 1}/${length}`}
                </Typography>
            </Box>
        </>
    );
};
