import {useEffect, useState} from "react";

const getRotationContainer = object => {
    if(object.name === 'rotationContainer') {
        return object
    } else if(object.parent) {
        return getRotationContainer(object.parent)
    } else {
        return null
    }
}

export const useRotationContainer = object => {
    const [rotationContainer, setRotationContainer] = useState(null)

    useEffect(
        () => {
            if(object) {
                setRotationContainer(getRotationContainer(object))
                return () => {
                    setRotationContainer(null)
                }
            }
        }, [object]
    )
    return rotationContainer
}