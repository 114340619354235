import {useRef} from 'react';
import {useThree} from '@react-three/fiber';
import {useModelViewerProps, useModelViewerState} from '../App/ModelViewerContext';
import {getIntersectsFromCamera, getMousePosition, isModel} from '../helpers/helpers';
import {addHotspot} from "../Hotspot/hotspot";
import {findOffsetPoint, findPoint} from "./modelHotspot";
import {ImageFront} from "./ImageFront";
import {ImageBack} from "./ImageBack";

export const Image = ({onScale}) => {
    const {
        data,
        onChange
    } = useModelViewerProps()
    const {
        addHotspotMode,
        scaleFactor
    } = useModelViewerState();
    const {
        camera,
        scene
    } = useThree();
    const modelRef = useRef()

    const handleAddHotspot = e => {
        if (addHotspotMode || e.detail === 2) {
            const mouse = getMousePosition(e);
            const intersections = getIntersectsFromCamera(camera, scene, mouse);
            const modelIntersected = intersections.find(i => isModel(i.object));
            if (modelIntersected) {
                const id = data.hotspots ? data.hotspots.length + 1 : 1;
                const lineStart = findPoint(modelIntersected, camera)
                const position = findOffsetPoint(modelIntersected, camera, scaleFactor)
                onChange(
                    addHotspot(data, {
                        id: id,
                        order: id,
                        faceNormal: {...modelIntersected.face.normal},
                        matrixWorld: {...modelIntersected.object.matrixWorld},
                        lineStart: {...lineStart},
                        position: {...position},
                        zoom: 0.25
                    })
                )
            }
        }
    };


    return (
        <group
            name="Model"
            ref={modelRef}
        >
            <ImageFront
                onClick={handleAddHotspot}
                onScale={onScale}
            />
            <ImageBack
                onClick={handleAddHotspot}
            />
        </group>
    );
};
