export const addHotspot = (data, hotspot) => ({
    ...data,
    hotspots: data.hotspots ? [...data.hotspots, hotspot] : [hotspot],
});

export const updateHotspot = (data, hotspotId, updatedData) => {
    const exists = data.hotspots.find(h => h.id === hotspotId);
    if (exists) {
        return {
            ...data,
            hotspots: data.hotspots.map(hotspot =>
                hotspot.id !== hotspotId
                    ? hotspot
                    : {
                        ...hotspot,
                        ...updatedData,
                    }
            ),
        };
    } else {
        throw new Error(`Hotspot with hotspotId: ${hotspotId}, does not exist`);
    }
};

export const removeHotspot = (data, hotspotId) => {
    const exists = data.hotspots.find(h => h.id === hotspotId);
    if (exists) {
        const remainingHotspots = [...data.hotspots.filter(h => h.id !== hotspotId)]
            .sort((a, b) => a.order - b.order)
            .map((hotspot, index) => ({
                ...hotspot,
                id: index + 1,
                order: index + 1,
            }));

        return {
            ...data,
            hotspots: remainingHotspots,
        };
    } else {
        throw new Error(`Hotspot with hotspotId: ${hotspotId}, does not exist`);
    }
};