import {useRef} from 'react';
import {createUseStyles} from 'react-jss';
import {Stepper} from './Stepper';
import {License} from './License';
import {Toolbar} from './Toolbar/Toolbar';
import {HelpDialog} from './HelpDialog';
import {Canvas} from './Canvas';
import {useModelViewerProps, useModelViewerState,} from '../App/ModelViewerContext';
import {AddHotspotButton} from '../Admin/AddHotspotButton';

const useStyles = createUseStyles({
    modelViewer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
});

export const ModelViewer = ({isMobile}) => {
    const {data} = useModelViewerProps();
    const {hotspots} = data;
    const {
        showAnnotations,
    } = useModelViewerState();
    const classes = useStyles();
    const canvasRef = useRef();
    const annotationList = [
        {
            id: 0,
            position: null,
            rotation: null,
            title: 'Velg infopunkt',
            description: '',
            zoom: null,
        },
    ].concat(hotspots ? hotspots : []);

    return (
        <div
            id='modelViewerCanvasContainer'
            className={classes.modelViewer}
            onContextMenu={e => e.preventDefault()}
        >
            <Canvas
                ref={canvasRef}
            />
            <Stepper
                show={!isMobile && showAnnotations && annotationList.length > 1}
                stepCount={annotationList.length}
            />
            <License />
            <Toolbar />
            <HelpDialog />
            <AddHotspotButton/>
        </div>
    );
};
