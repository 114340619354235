import { Box, Slider, TextField, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

export const SliderInput = ({
  title,
  min,
  max,
  step = 1,
  marks = false,
  value,
  disabled = false,
  onChange,
  sx,
}) => {
  const getRoundedValue = useCallback((value) => {
    return parseFloat(value.toFixed((step.toString().split('.')[1] ?? '').length));
  }, [step]);

  min = getRoundedValue(min);
  max = getRoundedValue(max);
  const [inputValue, setInputValue] = useState(getRoundedValue(value));

  useEffect(() => {
    setInputValue(getRoundedValue(value));
  }, [value, getRoundedValue]);

  const getValidValue = (value) => {
    value = getRoundedValue(value);
    value = Math.max(value, min);
    value = Math.min(value, max);
    return value;
  };

  const handleSliderChange = (event, value) => {
    setInputValue(value);
    onChange && onChange(value, false);
  };

  const handleChangeCommitted = (event, value) => {
    onChange && onChange(value, true);
  };

  const handleBlur = (event) => {
    let newValue = getValidValue(parseFloat(event.target.value));
    newValue = isNaN(newValue) ? min : newValue;

    if (newValue !== value) {
      setInputValue(newValue);
      onChange && onChange(newValue, true);
    }
    else if (newValue !== inputValue) {
      setInputValue(newValue);
    }
  };

  const handleInput = (event) => {
    if (!event.nativeEvent.inputType) {
      const value = parseFloat(event.target.value);
      setInputValue(value);
      onChange && onChange(value, false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      let newValue = getValidValue(parseFloat(event.target.value));
      newValue = isNaN(newValue) ? min : newValue;

      if (newValue !== value) {
        setInputValue(newValue);
        onChange && onChange(newValue, true);
      }
      else if (newValue !== inputValue) {
        setInputValue(newValue);
      }
    }
    else if (event.key === 'e' || event.key === 'E') {
      event.preventDefault();
    }
  };

  const handleTextChange = (event) => {
    const decimalCount = (step.toString().split('.')[1] ?? '').length;
    const value = Math.floor(parseFloat(event.target.value) * Math.pow(10, decimalCount)) / Math.pow(10, decimalCount);
    let newValue = getValidValue(value);
    newValue = isNaN(newValue) ? null : newValue;

    if (newValue !== inputValue) {
      setInputValue(newValue);
    }
    else {
      setInputValue(isNaN(value) ? null : value);
    }
  };

  return (
    <Box className='slider' sx={sx}>
      {title && <Typography>{title}</Typography>}
      <Slider
        size='small'
        min={min}
        max={max}
        step={step}
        marks={marks}
        value={inputValue ?? min}
        disabled={disabled}
        onChange={handleSliderChange}
        onChangeCommitted={handleChangeCommitted}
      />
      <TextField
        variant='outlined'
        type='number'
        value={inputValue ?? ''}
        disabled={disabled}
        onBlur={handleBlur}
        onInput={handleInput}
        onKeyDown={handleKeyDown}
        onChange={handleTextChange}
        InputProps={{
          inputProps: {
            min: min,
            max: max,
            step: step,
          },
        }}
        sx={{
          width: '110px',
        }}
      />
    </Box>
  );
};
