import {Help} from './Help';
import {Settings} from "./Settings";
import {Stack} from "@mui/material";
import {useModelViewerProps, useModelViewerState} from "../../App/ModelViewerContext";

export const Toolbar = () => {
    const {
        showToolbar
    } = useModelViewerState()

    const {data, adminMode} = useModelViewerProps();

    if(!adminMode && showToolbar) {
        return (
            <Stack
                direction={"row"}
                sx={{
                    right: 20,
                    bottom: 12,
                    position: "absolute"
                }}
            >
                <Help/>
                <Settings
                    data={data}
                />
            </Stack>
        )
    } else {
        return null
    }
};
