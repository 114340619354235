export const demo2 = {
    group: {
        length: 5,
        index: 1,
        name: 'Fridtjof Nansen',
    },
    model: {
        type: 'Object',
        dmsId: '0136NRjTvnvv',
        rotation: { x: Math.PI / -2, y: Math.PI / 2, z: 0 },
        info: {
            title: 'Lorem Ipsum',
            description: 'Nunc at sodales enim. Mauris porttitor sit amet ante nec rhoncus. Ut auctor dapibus urna et feugiat. Pellentesque dapibus porta turpis non euismod. Ut in tristique velit. Fusce sit amet venenatis libero, id maximus risus. Etiam fringilla elit nec neque fermentum aliquam. Etiam vel feugiat felis. Aliquam vulputate lobortis felis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In ut ipsum fringilla diam aliquet aliquet sodales sed mi. Maecenas efficitur ullamcorper erat, non tincidunt lectus aliquam ornare.',
        },
    },
}