import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
    SET_CAMERA_VALUES,
    SET_HELP_DIALOG_OPEN,
    useModelViewerDispatch,
} from '../../App/ModelViewerContext';
import {IconButton} from "@mui/material";

export const Help = () => {
    const dispatch = useModelViewerDispatch();

    const handleHelpDialogOpen = () => {
        dispatch({type: SET_HELP_DIALOG_OPEN, helpDialogOpen: true});
        dispatch({
            type: SET_CAMERA_VALUES,
            cameraValues: {
                pos: null,
                target: null,
            },
        });
    };

    return (
        <IconButton
            onClick={handleHelpDialogOpen}
            color={"primary"}
        >
            <HelpOutlineIcon
                fontSize={"large"}
            />
        </IconButton>
    );
};
