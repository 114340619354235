import { useState } from 'react';
import { TextField as MuiTextField } from '@mui/material';

export const TextField = ({
  id,
  label,
  value,
  onChange,
  variant = 'filled',
  ...rest
}) => {
  const [val, setVal] = useState(value);

  const handleChange = val => {
    setVal(val);
    onChange(val);
  };
  return (
    <MuiTextField
      id={id}
      label={label}
      variant={variant}
      value={val}
      onChange={e => handleChange(e.target.value)}
      {...rest}
    />
  );
};
