import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionSummary } from '@mui/material';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  accordion: {
    '&.MuiAccordion-root': {
      backgroundColor: 'unset',
      boxShadow: 'none',
      '&.Mui-expanded:before': {
        opacity: 1,
      },
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
  },
});

export const SettingsGroup = ({ title, expanded, onExpandChange, children }) => {
  const classes = useStyles();

  return (
    <Accordion
      disableGutters
      expanded={expanded}
      className={classes.accordion}
      onChange={onExpandChange(title)}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        className={classes.title}
      >
        {title}
      </AccordionSummary>
      {children}
    </Accordion>
  );
};
