import { Button } from '@mui/material';
import { Add, DeleteOutline, VolumeUp } from '@mui/icons-material';
import { useModelViewerProps, useModelViewerTranslation } from '../App/ModelViewerContext';
import { createUseStyles } from 'react-jss';
import { Links } from './Links';
import { TextField } from '../components/TextField';
import { updateModelInfo } from '../App/service';

const useStyles = createUseStyles({
  modelInfo: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    padding: 16,
  },
  audio: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
    '& div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 2,
    },
    '& svg': {
      color: 'rgba(0, 0, 0, 0.6)',
      '&.MuiSvgIcon-root': {
        width: 20,
        height: 20,
      },
    },
    '& > svg': {
      cursor: 'pointer',
    },
  },
});

export const EditModelInfo = ({ data }) => {
  const classes = useStyles();
  const { info } = data.model;
  const { title, description, links, audio } = info ?? {};
  const { onChange, onMediaSelect } = useModelViewerProps();
  const t = useModelViewerTranslation();

  const update = (key, value) => {
    onChange(
      updateModelInfo(data, {
        [key]: value,
      })
    );
  };

  const addLink = () => {
    update(
      'links',
      links ? [...links, { text: '', url: '' }] : [{ text: '', url: '' }]
    );
  };

  const updateLink = (key, value, index) => {
    const updatedLinks = links.map((link, i) =>
      index === i ? { ...link, [key]: value } : link
    );
    update('links', updatedLinks);
  };

  const deleteLink = (index) => {
    links.splice(index, 1);
    update(
      'links',
      links
    );
  };

  const addAudio = () => {
    if (onMediaSelect) {
      onMediaSelect('audio', async (audio) => {
        update(
          'audio',
          { dmsId: audio.dmsId, alt: audio.alt, volume: 1 }
        );
      }, 'single');
    } else {
      console.warn('onMediaSelect is undefined');
    }
  };

  const handleAudioDelete = () => {
    update(
      'audio',
      null
    );
  };

  return (
    <div className={classes.modelInfo}>
      <TextField
        id='label-textfield'
        label={'Tittel for objektet'}
        variant='filled'
        autoComplete='off'
        value={title || ''}
        onChange={value => update('title', value)}
        sx={{ margin: '10px 0' }}
      />
      <TextField
        id='description-textfield'
        label={'Beskrivelse av objektet'}
        variant='filled'
        sx={{ margin: '10px 0' }}
        multiline
        minRows={4}
        maxRows={6}
        value={description}
        onChange={value => update('description', value)}
      />
      <Links
        links={links}
        onDelete={deleteLink}
        onUpdate={updateLink}
      />
      <Button
        sx={{
          marginTop: '20px',
          color: '#00000099',
          justifyContent: 'flex-start',
        }}
        onClick={addLink}
      >
        <Add />
        {t('addHyperlink', 'Legg til lenke')}
      </Button>
      {!audio &&
        <Button
          sx={{
            color: '#00000099',
            justifyContent: 'flex-start',
          }}
          onClick={addAudio}
        >
          <Add />
          {t('addAudio', 'Legg til lyd')}
        </Button>
      }
      {audio && (
        <div className={classes.audio}>
          <div>
            <VolumeUp />
            {audio.alt}
          </div>
          <DeleteOutline onClick={handleAudioDelete} />
        </div>
      )}
    </div>
  );
};
