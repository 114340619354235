import {useEffect, useRef} from 'react';
import {getDmsFileUrl} from '@ekultur/dms-url-generator';
import {useThree} from '@react-three/fiber';

import {
    getIntersectsFromCamera,
    getModelAutoScale,
    getMousePosition,
    isModel,
} from '../helpers/helpers';
import {useModelViewerProps, useModelViewerState} from '../App/ModelViewerContext';
import {useCursor} from "@react-three/drei";
import {useMouseHover} from "../mouse/useHover";
import {addHotspot} from "../Hotspot/hotspot";
import {findOffsetPoint, findPoint} from "./modelHotspot";
import {useGLTF} from "./useGLTF";

export const Model = ({onScale}) => {
    const {
        data,
        onChange
    } = useModelViewerProps()
    const {model} = data;
    const {
        url,
        dmsId
    } = model;
    const {
        camera,
        scene
    } = useThree();
    const {
        addHotspotMode,
        scaleFactor
    } = useModelViewerState();
    const {
        hover,
        untoggleHover,
        toggleHover
    } = useMouseHover()
    const {scene: modelScene} = useGLTF(
        url || getDmsFileUrl(dmsId, '01')
    );
    const modelRef = useRef()
    useCursor(
        hover,
        'crosshair',
    )

    useEffect(() => {
        onScale(getModelAutoScale(modelScene, camera));
    }, [dmsId, url]);

    useEffect(
        () => {

        }, [addHotspotMode]
    )

    const handleAddHotspot = e => {
        if (addHotspotMode || e.detail === 2) {
            const mouse = getMousePosition(e);
            const intersections = getIntersectsFromCamera(camera, scene, mouse);
            const modelIntersected = intersections.find(i => isModel(i.object));
            if (modelIntersected) {
                const id = data.hotspots ? data.hotspots.length + 1 : 1;
                const lineStart = findPoint(modelIntersected, camera)
                const position = findOffsetPoint(modelIntersected, camera, scaleFactor)
                onChange(
                    addHotspot(data, {
                        id: id,
                        order: id,
                        faceNormal: {...modelIntersected.face.normal},
                        matrixWorld: {...modelIntersected.object.matrixWorld},
                        lineStart: {...lineStart},
                        position: {...position},
                        zoom: 0.25
                    })
                )
            }
        }
    };

    return modelScene ? (
        <primitive
            name="Model"
            object={modelScene}
            onClick={handleAddHotspot}
            ref={modelRef}
            onPointerOver={addHotspotMode && toggleHover}
            onPointerOut={addHotspotMode && untoggleHover}
        />
    ) : null;
};
