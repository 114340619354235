import {Box3, Euler, Raycaster, Sphere, Vector2, Vector3} from 'three';

export const isMobileDevice = () => {
    return /Android|iPhone/i.test(navigator.userAgent);
};

export const convertToThreeJsTypes = object => {
    if (object !== null && typeof object === 'object') {
        Object.entries(object).forEach(([key, value]) => {
            if (
                ['lineStart', 'position', 'startPosition', 'scale'].includes(key) &&
                !Array.isArray(value) &&
                typeof value === 'object'
            ) {
                Object.keys(value).forEach(k => {
                    if (!isNaN(parseFloat(value[k]))) {
                        value[k] = parseFloat(value[k]);
                    }
                });
                object[key] = Object.assign(new Vector3(), value);
            } else if (
                ['rotation', 'directionOfView'].includes(key) &&
                !Array.isArray(value) &&
                typeof value === 'object'
            ) {
                Object.keys(value).forEach(k => {
                    if (!isNaN(parseFloat(value[k]))) {
                        value[k] = parseFloat(value[k]);
                    }
                });
                object[key] = Object.assign(new Euler(), value);
            } else {
                convertToThreeJsTypes(value);
            }
        });
    }

    return object;
};

export const getRotationContainer = object => {
    if(object.name === 'rotationContainer') {
        return object
    } else if(object.parent) {
        return getRotationContainer(object.parent)
    } else {
        return null
    }
};

export const getMousePosition = event => {
    const canvas = document.getElementById('modelViewerCanvasContainer');
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const mouse = new Vector2();
    mouse.x = (x / rect.width) * 2 - 1;
    mouse.y = -(y / rect.height) * 2 + 1;
    return mouse;
};

export const getIntersectsFromCamera = (camera, scene, coords) => {
    const raycaster = new Raycaster();
    raycaster.setFromCamera(coords, camera);
    return raycaster.intersectObjects(scene.children, true);
};

export const getPositionInDirection = (position, direction, distance) => {
    const raycaster = new Raycaster();
    const target = new Vector3();

    raycaster.set(position, direction);
    raycaster.ray.at(distance, target);

    return target;
};

export const isModel = object => {
    if(object.name?.startsWith('Model')) {
        return true
    } else if(object.parent) {
        return isModel(object.parent)
    } else {
        return false
    }
}


export const getModelAutoScale = (model, camera) => {
    const bbox = new Box3();
    const sphere = new Sphere();
    bbox.setFromObject(model.clone());
    bbox.getBoundingSphere(sphere);

    const fov = camera.fov * Math.PI / 180;
    const fovh = 2 * Math.atan(Math.tan(fov / 2) * camera.aspect);
    let dx = sphere.radius + Math.abs(sphere.radius / Math.tan(fovh / 2));
    let dy = sphere.radius + Math.abs(sphere.radius / Math.tan(fov / 2));
    let cameraZ = Math.max(dx, dy);

    const scale = 1.35 / cameraZ;
    return new Vector3(scale, scale, scale);
};
