import {Box, Drawer, IconButton} from '@mui/material';
import {ArrowBack, OpenInNew} from '@mui/icons-material';
import {AudioController} from '../Audio/AudioController';
import {Audio} from '../Audio/Audio';
import {createUseStyles} from 'react-jss';
import {MobileDrawer} from '../components/MobileDrawer';
import {useCallback} from 'react';

const useStyles = createUseStyles({
  desktop: {
    width: (props) => props.open ? '33%' : 0,
    flexShrink: 0,
    transition: 'width 225ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    '& .MuiDrawer-paper': {
      width: '33%',
      backgroundColor: '#F5F5F7',
      padding: '100px 4% 20px 4%',
      gap: 20,
      fontFamily: 'Akkurat',
      boxSizing: 'border-box',
      position: 'absolute',
    },
  },
  mobile: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    padding: '5%',
    fontFamily: 'Akkurat',
    boxSizing: 'border-box',
  },
  title: {
    fontSize: 30,
    fontWeight: 700,
    paddingRight: (props) => props.mobile ? 40 : 0,
  },
  description: {
    fontSize: 15,
    fontWeight: 400,
    lineHeight: '160%',
  },
  audio: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& > p': {
      fontSize: 14,
      paddingLeft: 20,
    },
  },
  link: {
    color: '#000000',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    fontSize: 14,
  },
});

export const ModelInfo = ({ data, open, isMobile, onToggle }) => {
  const { dmsId, url, info } = data;
  const { title, description, audio, links } = info;
  const classes = useStyles({ open: open, mobile: isMobile });

  const renderContent = useCallback(() => {
    return (
      <>
        <div className={classes.title}>{title}</div>
        {audio && (
          <div className={classes.audio}>
            <AudioController>
              <Audio {...audio} />
            </AudioController>
            <p>Hør lydklipp</p>
          </div>
        )}
        <div className={classes.description}>{description}</div>
        <div>
          {links && links.map(({ url, text }, i) => (
            <a
              className={classes.link}
              href={url}
              key={`modellink-${i}`}
              target="_blank"
              rel="noreferrer"
            >
              <OpenInNew sx={{ marginRight: '5px' }} /> {text}
            </a>
          ))}
        </div>
      </>
    );
  }, [dmsId, url]);

  return (
    <>
      {!isMobile &&
        <div className={classes.desktop}>
          <Drawer
            anchor={'left'}
            variant={'persistent'}
            open={open}
          >
            <Box
                sx={{
                  position: 'absolute',
                  right: 20,
                  top: 20
                }}
            >
              <IconButton
                  onClick={onToggle}
              >
                <ArrowBack />
              </IconButton>
            </Box>
            {renderContent()}
          </Drawer>
        </div>
      }
      {isMobile &&
        <MobileDrawer open={open} onToggle={onToggle}>
          <div className={classes.mobile}>
            {renderContent()}
          </div>
        </MobileDrawer>
      }
    </>
  );
};
