import { MathUtils } from 'three';
import { Typography } from '@mui/material';
import { SliderInput } from '../components/SliderInput';
import { useModelViewerProps } from '../App/ModelViewerContext';
import { updateModelRotation } from '../App/service';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  generalSettings: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    padding: 16,
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
  },
});

export const EditGeneralSettings = ({ data }) => {
  const classes = useStyles();
  const { rotation } = data.model;
  const { onChange } = useModelViewerProps();

  const updateRotation = (key, value) => {
    onChange(
      updateModelRotation(data, {
        [key]: MathUtils.degToRad(value) - Math.PI,
      })
    );
  };

  return (
    <div className={classes.generalSettings}>
      <div>
        <Typography>Rotasjon</Typography>
        <SliderInput
          title={'X'}
          value={MathUtils.radToDeg(rotation.x + Math.PI)}
          min={0}
          max={360}
          step={0.1}
          onChange={value => updateRotation('_x', value)}
        />
        <SliderInput
          title={'Y'}
          value={MathUtils.radToDeg(rotation.y + Math.PI)}
          min={0}
          max={360}
          step={0.1}
          onChange={value => updateRotation('_y', value)}
        />
        <SliderInput
          title={'Z'}
          value={MathUtils.radToDeg(rotation.z + Math.PI)}
          min={0}
          max={360}
          step={0.1}
          onChange={value => updateRotation('_z', value)}
        />
      </div>
    </div>
  );
};
