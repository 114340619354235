import {Stack, useMediaQuery, useTheme} from "@mui/material";

export const Instructions = ({children}) => {
    const theme = useTheme()
    const desktop = useMediaQuery(theme.breakpoints.up('sm'))
    return (
        <Stack
            direction={desktop ? "row" : "column"}
            spacing={desktop ? 10 : 4}
            alignItems={"center"}
            justifyContent={"space-evenly"}
            sx={{
                m: 4
            }}
        >
            {children}
        </Stack>
    )
}