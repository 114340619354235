import {useEffect, useRef, useState} from 'react';
import {Line, Sphere, useCursor} from '@react-three/drei';
import {
    SET_CAMERA_CONTROLS_ENABLED,
    useModelViewerDispatch,
    useModelViewerProps,
    useModelViewerState,
} from '../App/ModelViewerContext';
import {useThree} from '@react-three/fiber';
import {Quaternion, Vector3} from 'three';
import {useGesture} from "@use-gesture/react";
import {useMouseHover} from "../mouse/useHover";
import {useCanvasWidth} from "../Canvas/useCanvasWidth";
import {HotspotContent} from "./HotspotContent";
import {useTheme} from "@mui/material";

export const Hotspot = ({
                            hotspot,
                            id,
                            isSelected,
                            onChange,
                            modelRotation,
                            rotationContainer
                        }) => {
    const dispatch = useModelViewerDispatch();
    const {adminMode} = useModelViewerProps()
    const {
        hover,
        untoggleHover,
        toggleHover
    } = useMouseHover()
    const {
        cameraControlsEnabled,
        scaleFactor
    } = useModelViewerState()
    const {title, position, description, links, lineStart, faceNormal, matrixWorld} = hotspot;
    const {camera} = useThree();
    const [dragPosition, setDragPosition] = useState([position.x, position.y, position.z]);
    const hotspotRef = useRef()
    const {canvasWidth} = useCanvasWidth()
    const theme = useTheme()

    useCursor(
        hover,
        isSelected ? "grab" : "pointer"
    )

    useEffect(() => {
        setDragPosition([position.x, position.y, position.z]);
    }, [position.x, position.y, position.z]);

    useEffect(
        () => {
            if (hotspotRef.current) {
                const lookAtThis = new Vector3(faceNormal.x, faceNormal.y, faceNormal.z)
                lookAtThis.transformDirection(matrixWorld)
                hotspotRef.current.lookAt(lookAtThis)
            }
        }, [hotspotRef?.current]
    )


    const handleDrag = ({active, down, xy}) => {
        if (!down || !active) {
            return {}
        }

        const clipPos = new Vector3(...dragPosition)
        clipPos.project(camera)
        let vector = new Vector3(
            (xy[0] / canvasWidth) * 2 - 1,
            -(xy[1] / window.innerHeight) * 2 + 1,
            clipPos.z
        )
        vector.unproject(camera)
        const point = rotationContainer.worldToLocal(vector.clone())
        setDragPosition([point.x, point.y, point.z])
    }

    const handleDragStart = () => {
        if (cameraControlsEnabled) {
            dispatch({
                type: SET_CAMERA_CONTROLS_ENABLED,
                cameraControlsEnabled: false,
            })
        }
    }

    const handleDragEnd = () => {
        dispatch({
            type: SET_CAMERA_CONTROLS_ENABLED,
            cameraControlsEnabled: true,
        })

        // Copied from old code, doesn't fully understand yet.
        const cameraRotation = new Vector3(0, 0, 1).applyEuler(camera.rotation).negate();
        const quat = new Quaternion().setFromEuler(modelRotation).conjugate();
        const rotation = cameraRotation.clone().applyQuaternion(quat);

        onChange(id, {
            position: new Vector3(dragPosition[0], dragPosition[1], dragPosition[2]),
            rotation: rotation,
        })
    }


    const gestureHandlers = useGesture(
        {
            onDrag: handleDrag,
            onDragStart: handleDragStart,
            onDragEnd: handleDragEnd
        },
        {
            drag: {
                from: () => [dragPosition[0], dragPosition[1]]
            },
            enabled: adminMode
        }
    )

    return (
        <group
            name={`hotspot-${id}`}
            userData={{
                id: id
            }}
        >
            <Line
                points={[lineStart, adminMode ? dragPosition : position]}
                color="black"
                lineWidth={1}
                segments={true}
                dashed={false}
            />
            <Sphere
                scale={0.025 / scaleFactor}
                {...gestureHandlers()}
                position={adminMode ? dragPosition : position}
                onPointerOver={toggleHover}
                onPointerOut={untoggleHover}
                ref={hotspotRef}
            >
                <meshStandardMaterial color={isSelected ? theme.palette.secondary.main : theme.palette.primary.main}/>
            </Sphere>
            <HotspotContent
                anchorPosition={adminMode ? dragPosition : position}
                open={isSelected && cameraControlsEnabled}
                title={title}
                description={description}
                links={links}
                rotationContainer={rotationContainer}
            />
        </group>
    )

};
