import {Stack} from "@mui/material";

export const Instruction = ({children}) => {
    // instruction: {
    //     display: 'flex',
    //         flexDirection: 'column',
    //         alignItems: 'center',
    //         gap: 30,
    //         '& svg': {
    //         '@media (max-width: 700px)': {
    //             scale: '75%',
    //         },
    //     },
    //     '& p': {
    //         width: 175,
    //             textAlign: 'center',
    //             marginBlockStart: 0,
    //             marginBlockEnd: 0,
    //             fontSize: 20,
    //             '@media (max-width: 700px)': {
    //             width: 160,
    //                 fontSize: 18,
    //         },
    //     },
    // }

    return (
        <Stack
            alignItems={"center"}
            spacing={3}
        >
            {children}
        </Stack>
    )
}