import {createUseStyles} from 'react-jss';
import {Box} from "@mui/material";

const useStyles = createUseStyles({
    viewport: {
        width: '100%',
        height: '100%',
        // background: `
        // radial-gradient(
        //   50% 50% at 50% 50%,
        //   rgba(255, 255, 255, 0.2) 0%,
        //   rgba(255, 255, 255, 0) 100%
        // ),
        // linear-gradient(0deg, #222222, #222222),
        // #232225`,
    },
    ellipsis: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 80,
        height: 80,
        transform: 'translateX(-50%) translateY(-50%)',
        '& div': {
            position: 'absolute',
            top: 33,
            width: 13,
            height: 13,
            borderRadius: '50%',
            background: '#F5F5F7',
            animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)',
        },
        '& div:nth-child(1)': {
            left: 8,
            animation: '$ellipsis1 0.6s infinite',
        },
        '& div:nth-child(2)': {
            left: 8,
            animation: '$ellipsis2 0.6s infinite',
        },
        '& div:nth-child(3)': {
            left: 32,
            animation: '$ellipsis2 0.6s infinite',
        },
        '& div:nth-child(4)': {
            left: 56,
            animation: '$ellipsis3 0.6s infinite',
        },
    },
    '@keyframes ellipsis1': {
        '0%': {
            transform: 'scale(0)',
        },
        '100%': {
            transform: 'scale(1)',
        },
    },
    '@keyframes ellipsis3': {
        '0%': {
            transform: 'scale(1)',
        },
        '100%': {
            transform: 'scale(0)',
        },
    },
    '@keyframes ellipsis2': {
        '0%': {
            transform: 'translate(0, 0)',
        },
        '100%': {
            transform: 'translate(24px, 0)',
        },
    },
});

export const LoadingAnimation = () => {
    const classes = useStyles();

    return (
        <Box
            className={classes.viewport}
            sx={{
                backgroundColor: 'grey.300'
            }}
        >
            <div className={classes.ellipsis}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </Box>
    );
};
