import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { createUseStyles } from 'react-jss';
import * as Icon from 'react-feather';

const useStyles = createUseStyles({
  drawer: {
    '&.MuiDrawer-root': {
      zIndex: 999999999,
      '& > .MuiPaper-root': {
        backgroundColor: '#F5F5F7',
        touchAction: 'pan-y',
      },
    },
  },
  toggle: {
    position: 'absolute',
    top: 20,
    right: 20,
  },
});

export const MobileDrawer = ({
  open,
  onToggle,
  children,
}) => {
  const styles = useStyles();

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={() => onToggle(false)}
      onOpen={() => onToggle(true)}
      disableSwipeToOpen={true}
      className={styles.drawer}
      ModalProps={{
        keepMounted: false,
      }}
    >
      <div className={styles.toggle} onClick={() => onToggle(false)}>
        <Icon.ArrowDown />
      </div>
      {children}
    </SwipeableDrawer>
  );
};
