import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '30px',
    overflow: 'auto',
    height: 'calc(100% - 60px)',
  },
  list: {
    padding: '8px 0',
    margin: 0,
    listStyle: 'none',
    '& > li': {
      padding: '12px 16px',
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
  },
});

export const DemoLinks = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <h2>Modeller</h2>
        <ul className={classes.list}>
          <li>
            <Link to="/model/1">Modell 1</Link>
          </li>
          <li>
            <Link to="/model/2">Modell 2</Link>
          </li>
          <li>
            <Link to="/model/3">Modell 3</Link>
          </li>
          <li>
            <Link to="/model/4">Modell 4</Link>
          </li>
          <li>
            <Link to="/model/5">Modell 5</Link>
          </li>
          <li>
            <Link to="/model/6">Modell 6</Link>
          </li>
          <li>
            <Link to="/model/7">Modell 7</Link>
          </li>
          <li>
            <Link to="/model/8">Modell 8</Link>
          </li>
          <li>
            <Link to="/model/9">Modell 9</Link>
          </li>
          <li>
            <Link to="/model/10">Modell 10</Link>
          </li>
          <li>
            <Link to="/model/11">Modell 11</Link>
          </li>
          <li>
            <Link to="/model/12">Modell 12</Link>
          </li>
          <li>
            <Link to="/model/13">Modell 13</Link>
          </li>
          <li>
            <Link to="/model/14">Modell 14</Link>
          </li>
          <li>
            <Link to="/model/15">Modell 15</Link>
          </li>
          <li>
            <Link to="/model/16">Telefon</Link>
          </li>
          <li>
            <Link to="/model/17">G1 Diamanten auto optimized</Link>
          </li>
          <li>
            <Link to="/model/18">G1 Diamanten auto optimized 2048x2048</Link>
          </li>
          <li>
            <Link to="/model/19">Faering</Link>
          </li>
          <li>
            <Link to="/model/20">Faering optimized</Link>
          </li>
          <li>
            <Link to="/model/21">NSM-13352-optimized</Link>
          </li>
          <li>
            <Link to="/model/22">NSM-13352-optimized 2048x2048</Link>
          </li>
          <li>
            <Link to="/model/23">NSM-13352-optimized 1024x1024</Link>
          </li>
          <li>
            <Link to="/model/24">NSM-13352-optimized-unlit 1024x1024</Link>
          </li>
        </ul>
      </div>
      <div>
        <h2>Admin</h2>
        <ul className={classes.list}>
          <li>
            <Link to="/admin/1">Modell 1</Link>
          </li>
          <li>
            <Link to="/admin/2">Modell 2</Link>
          </li>
          <li>
            <Link to="/admin/3">Modell 3</Link>
          </li>
          <li>
            <Link to="/admin/4">Modell 4</Link>
          </li>
          <li>
            <Link to="/admin/5">Modell 5</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
