import {SET_SHOW_ANNOTATIONS, useModelViewerDispatch, useModelViewerState} from "../../App/ModelViewerContext";
import {FormControlLabel, Switch} from "@mui/material";

export const ShowHotspotsToggle = ({hasHotspots, onClick}) => {
    const dispatch = useModelViewerDispatch();
    const {showAnnotations} = useModelViewerState()

    const handleAnnotationToggle = e => {
        e.preventDefault();
        dispatch({
            type: SET_SHOW_ANNOTATIONS,
            showAnnotations: e.target.checked,
        })
        onClick()
    }

    if(hasHotspots) {
        return (
            <FormControlLabel
                control={
                    <Switch
                        checked={showAnnotations}
                        onChange={handleAnnotationToggle}
                        inputProps={{ 'aria-label': 'show-hotspots' }}
                    />
                }
                label="Annoteringer"
            />
        )
    } else {
        return null
    }
}