import { useEffect, useMemo, useRef } from 'react';
import { getAudioUrl } from '@ekultur/dms-url-generator';
import { Stopwatch } from './Stopwatch';

export const Audio = ({
  url,
  dmsId,
  volume = 1,
  loop = false,
  paused = true,
  onProgressChange,
  onEnded,
}) => {
  const sound = useRef();
  const stopwatch = useMemo(() => new Stopwatch(), []);

  setInterval(() => {
    if (!sound.current || paused) return;

    if (onProgressChange) {
      const percent = stopwatch.elapsedTime() / sound.current.duration * 100;
      onProgressChange(percent);
    }
  }, 100);

  useEffect(() => {
    if (paused && !sound.current.paused) {
      sound.current.pause();
      stopwatch.stop();
    }
    else if (!paused && sound.current.paused) {
      sound.current.play();
      stopwatch.start();
    }
  }, [paused, stopwatch]);

  const handleEnded = () => {
    if (!loop) {
      sound.current.pause();
      stopwatch.reset();
    }

    onEnded && onEnded(loop);
  };

  return (
    <audio
      ref={sound}
      loop={loop}
      volume={volume}
      onEnded={handleEnded}
    >
      <source src={dmsId ? getAudioUrl(dmsId) : url} />
    </audio>
  );
};
