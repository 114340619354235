import {IconButton, MobileStepper} from "@mui/material";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {
    STEPPER_NEXT_CLICKED,
    STEPPER_PREVIOUS_CLICKED,
    useModelViewerDispatch,
    useModelViewerState
} from "../App/ModelViewerContext";


export const Stepper = ({show, stepCount}) => {
    const dispatch = useModelViewerDispatch()
    const {activeHotspot} = useModelViewerState()

    const handlePrevious = () => {
        dispatch({type: STEPPER_PREVIOUS_CLICKED})
    }

    const handleNext = () => {
        dispatch({type: STEPPER_NEXT_CLICKED})
    }

    if (show) {
        return (
            <MobileStepper
                variant={"progress"}
                steps={stepCount}
                activeStep={activeHotspot}
                nextButton={
                    <IconButton
                        onClick={handleNext}
                        disabled={stepCount - 1 === activeHotspot}
                    >
                        <KeyboardArrowRight/>
                    </IconButton>
                }
                backButton={
                    <IconButton
                        onClick={handlePrevious}
                        disabled={0 === activeHotspot}
                    >
                        <KeyboardArrowLeft/>
                    </IconButton>
                }
                sx={{
                    position: "absolute",
                    left: '50%',
                    transform: 'translateX(-50%)',
                    bottom: '3vh',
                    width: 300
                }}
            />
        )
    } else {
        return null
    }
};
