export const demo17 = {
    model: {
        type: 'Object',
        dmsId: '019EBwjjymF1Y',
        rotation: { x: -1.5882, y: 0.0087, z: 1.4312 },
        info: {
            title: 'Lorem Ipsum',
            description: 'Duis et venenatis ipsum. Mauris id placerat purus. Nullam rhoncus, erat at volutpat molestie, quam nibh scelerisque libero, vitae sagittis ligula lacus eu neque. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce ut urna fermentum, auctor erat nec, posuere lectus. Pellentesque tincidunt sapien libero, at rutrum ante tincidunt quis. Morbi tempus accumsan risus iaculis scelerisque. Suspendisse non auctor tortor. Aenean dapibus lorem nisi, ut commodo leo auctor nec.',
        },
    },
}