import {RESET_CAMERA, useModelViewerDispatch} from "../../App/ModelViewerContext";
import {Button} from "@mui/material";

export const ResetCameraButton = ({onClick}) => {
    const dispatch = useModelViewerDispatch();

    const handleCameraReset = () => {
        dispatch({
            type: RESET_CAMERA
        })
        onClick()
    };

    return (
        <Button
            onClick={handleCameraReset}
        >
            Tilbakestill kamera
        </Button>
    )
}