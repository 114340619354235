import {getRotationContainer} from "../helpers/helpers";

export const findPoint = (modelIntersected, camera) => {
    const rotationContainer = getRotationContainer(modelIntersected.object)
    const point = rotationContainer.worldToLocal(modelIntersected.point.clone())
    const onset = camera.position.clone().sub(modelIntersected.point.clone()).normalize().multiplyScalar(0.001);
    return point.add(rotationContainer.worldToLocal(onset))
}

export const findOffsetPoint = (modelIntersected, camera, scaleFactor) => {
    const rotationContainer = getRotationContainer(modelIntersected.object)
    const point = rotationContainer.worldToLocal(modelIntersected.point.clone())
    const onset = camera.position.clone().sub(modelIntersected.point.clone()).normalize().multiplyScalar(4 / scaleFactor * 0.038);
    return point.add(rotationContainer.worldToLocal(onset))
}