import {useRef, useState} from 'react';
import {Add, DeleteOutline, DragHandle, ExpandMore,} from '@mui/icons-material';
import {createUseStyles} from 'react-jss';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import {TextField} from '../components/TextField';
import {
  SET_ACTIVE_HOTSPOT,
  useModelViewerDispatch,
  useModelViewerProps,
  useModelViewerTranslation,
} from '../App/ModelViewerContext';
import {useDrag, useDrop} from 'react-dnd';
import {Links} from './Links';
import {SliderInput} from '../components/SliderInput';
import {removeHotspot, updateHotspot} from "../Hotspot/hotspot";

const useStyles = createUseStyles({
  icon: {
    cursor: 'move',
  },
  index: {
    width: '24px',
    height: '24px',
    border: '1px solid #0C0B0F',
    color: '#0C0B0F',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '479.52px',
    margin: '0 10px 0 10px',
  },
});

const StyledAccordionSummary = styled(AccordionSummary)({
  '.MuiAccordionSummary-content': {
    alignItems: 'center',
  },
});

export const EditHotspot = ({
  index,
  hotspot,
  data,
  expanded,
  onExpandChange,
  moveCard,
  resetCards,
  dropCard,
}) => {
  const { description, title, id, links, zoom } = hotspot;
  const { onChange } = useModelViewerProps();
  const t = useModelViewerTranslation();
  const dispatch = useModelViewerDispatch();
  const [handlePressed, setHandlePressed] = useState(false);
  const ref = useRef();

  const [{ handlerId }, drop] = useDrop({
    accept: 'Hotspot',
    collect: monitor => {
      return { handlerId: monitor.getHandlerId() };
    },
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop: dropCard,
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'Hotspot',
    item: () => {
      return { order: hotspot.order, index };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (_item, monitor) => {
      setHandlePressed(false);
      if (!monitor.didDrop()) {
        resetCards();
      }
    },
    canDrag: handlePressed,
  });

  const update = (key, value) => {
    onChange(
      updateHotspot(data, id, {
        [key]: value,
      })
    );
  };

  const addLink = () => {
    update(
      'links',
      links ? [...links, { text: '', url: '' }] : [{ text: '', url: '' }]
    );
  };

  const updateLink = (key, value, index) => {
    const updatedLinks = links.map((link, i) =>
      index === i ? { ...link, [key]: value } : link
    );
    update('links', updatedLinks);
  };

  const deleteLink = (index) => {
    links.splice(index, 1);
    update(
      'links',
      links
    );
  };

  const onDelete = () => {
    dispatch({
      type: SET_ACTIVE_HOTSPOT,
      activeHotspot: 0,
    });
    onChange(removeHotspot(data, id))
  };

  const classes = useStyles({ isDragging });
  drag(drop(ref));

  return (
    <Accordion
      ref={ref}
      disableGutters
      expanded={!isDragging && expanded === hotspot?.id}
      onChange={onExpandChange(hotspot?.id)}
      data-handler-id={handlerId}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        opacity: isDragging ? 0 : 1,
        border: isDragging ? '1px dotted black' : 'none',
      }}
    >
      <StyledAccordionSummary
        ref={preview}
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Tooltip
          title={t('reorderHotspotTooltip', 'Dra for å endre rekkefølge')}
        >
          <IconButton onMouseDown={() => setHandlePressed(true)}>
            <DragHandle className={classes.icon} />
          </IconButton>
        </Tooltip>
        <div className={classes.index}>{index + 1}</div>
        <Typography
          sx={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '135px',
            overflow: 'hidden',
          }}
        >
          {hotspot?.title || t('noTitle', 'Uten tittel')}
        </Typography>
      </StyledAccordionSummary>
      <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
        <TextField
          id='label-textfield'
          label={t('labelLabel', 'Label')}
          variant='filled'
          autoComplete='off'
          value={title || ''}
          onChange={value => update('title', value)}
          sx={{ margin: '10px 0' }}
        />
        <TextField
          id='description-textfield'
          label={t('descriptionLabel', 'Beskrivelse')}
          variant='filled'
          sx={{ margin: '10px 0' }}
          multiline
          minRows={4}
          maxRows={6}
          value={description}
          onChange={value => update('description', value)}
        />
        <SliderInput
          title={'Zoom'}
          value={((((zoom || 0.25) - 0.05) * 200) - 100) * -1}
          min={0}
          max={100}
          step={10}
          onChange={(value, last) => {
            last && update('zoom', (((value - 100) / 200) * -1) + 0.05);
          }}
          sx={{
            margin: '10px 0',
          }}
        />
        <Links
          links={links}
          onDelete={deleteLink}
          onUpdate={updateLink}
        />
        <Button
          sx={{
            marginTop: '20px',
            color: '#00000099',
            justifyContent: 'flex-start',
          }}
          onClick={addLink}
        >
          <Add />
          {t('addHyperlink', 'Legg til lenke')}
        </Button>
        <Button
          sx={{ color: '#00000099', justifyContent: 'flex-start' }}
          onClick={onDelete}
        >
          <DeleteOutline />
          {t('deleteTextHotspot', 'Fjern infopunkt')}
        </Button>
      </AccordionDetails>
    </Accordion>
  );
};
