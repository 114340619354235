import {Box, IconButton, Popover, Stack} from "@mui/material";
import CopyrightIcon from '@mui/icons-material/Copyright';
import {useState} from "react";
import {LicenseItem} from "./LicenseItem";
import {useModelViewerProps} from "../App/ModelViewerContext";


export const License = () => {
    const [anchorEl, setAnchorEl] = useState(null)
    const {data} = useModelViewerProps()
    const {license} = data


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? "license-popover" : undefined

    if (license) {
        const { creatorName, creatorUrl, licenseName, licenseUrl, sourceName, sourceUrl } = license
        return (
            <Box
                sx={{
                    position: 'absolute',
                    top: 30,
                    left: 30,
                }}
            >
                <IconButton
                    onClick={handleClick}
                    color={"primary"}
                >
                    <CopyrightIcon
                        fontSize={"large"}
                    />
                </IconButton>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <Stack
                        spacing={1}
                        sx={{
                            padding: 2
                        }}
                    >
                        <LicenseItem
                            label={"Creator"}
                            url={creatorUrl}
                            value={creatorName}
                        />
                        <LicenseItem
                            label={"Source"}
                            url={sourceUrl}
                            value={sourceName}
                        />
                        <LicenseItem
                            label={"License"}
                            url={licenseUrl}
                            value={licenseName}
                        />
                    </Stack>
                </Popover>
            </Box>
        )
    } else {
        return null
    }

};