import {useEffect, useRef} from 'react';
import {useFrame, useThree} from '@react-three/fiber';
import {OrbitControls} from '@react-three/drei';

import {Gizmo} from './Gizmo';
import {useModelViewerState,} from '../App/ModelViewerContext';
import {ModelTypes, ZoomDistance} from '../helpers/enums';
import {animated, easings, useSpring} from "@react-spring/three";
import {useLookAt} from "./useLookAt";

const AnimatedOrbitControls = animated(OrbitControls);

export const CameraControls = ({
    showGizmo,
    enablePan,
    enableZoom,
    model,
    rotationContainer
                               }) => {
    const controls = useRef();
    const {camera} = useThree();
    const {
        cameraControlsEnabled,
    } = useModelViewerState();

    const {1: spring} = useSpring(() => ({}));
    const imageSingle = model.type === ModelTypes.IMAGE_SINGLE;
    const imageDouble = model.type === ModelTypes.IMAGE_DOUBLE;
    const cameraTo = useLookAt({rotationContainer: rotationContainer})

    useFrame(() => controls.current?.update());

    const fromPos = [...camera.position];
    const fromTarget = controls.current
        ? [...controls.current?.target]
        : [0, 0, 0];

    useEffect(() => {
        camera.position.set(0, 0, -1);
    }, [model.dmsId, model.url, model.rotation?.x, model.rotation?.y, model.rotation?.z]);

    useEffect(() => {
        if(cameraTo.position && cameraTo.target) {
            spring.start({
                from: {
                    pos: fromPos,
                    target: fromTarget,
                },
                to: {
                    pos: cameraTo.position,
                    target: cameraTo.target
                },
                reset: true,
                config: {
                    duration: 2700,
                    easing: easings.easeInOutCubic,
                },
                onChange: ({value: {pos, target}}) => {
                    camera.position.set(...pos);
                    controls.current.target.set(...target);
                },
            })
        }
    }, [cameraTo]);

    return (
        <>
            <AnimatedOrbitControls
                ref={controls}
                enablePan={enablePan}
                enableZoom={enableZoom}
                maxDistance={ZoomDistance.MAX}
                minDistance={ZoomDistance.MIN}
                zoomSpeed={3}
                minAzimuthAngle={imageSingle ? Math.PI * 0.6 : -Infinity}
                maxAzimuthAngle={imageSingle ? Math.PI * 1.4 : Infinity}
                minPolarAngle={imageSingle || imageDouble ? 0.5 : 0}
                maxPolarAngle={imageSingle || imageDouble ? Math.PI - 0.5 : Math.PI}
                enabled={cameraControlsEnabled}
                makeDefault={true}
            />
            {showGizmo && (
                <Gizmo
                    xColor="#f48fb1"
                    yColor="#8bc34a"
                    zColor="#90caf9"
                    labelColor="black"
                    onUpdate={() => controls.current?.update()}
                    onTarget={() => controls.current?.target}
                />
            )}
        </>
    );
};
