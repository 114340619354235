import {useCallback, useEffect, useState} from 'react';
import {EditHotspot} from './EditHotspot';
import {
  SET_ACTIVE_HOTSPOT,
  useModelViewerDispatch,
  useModelViewerState,
  useModelViewerTranslation,
} from '../App/ModelViewerContext';
import {createUseStyles} from 'react-jss';

const useStyles = createUseStyles({
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    padding: 10,
    '& .MuiAccordion-root': {
      borderRadius: 4,
      boxShadow: '0px 0px 0px 1px #E0E0E0',
      '&:hover': {
        boxShadow: '0px 0px 0px 1px #CACACA',
      },
      '&:before': {
        display: 'none',
      },
      '& .MuiAccordionSummary-root': {
        paddingLeft: 6,
      },
      '& .MuiAccordionSummary-content': {
        margin: '6px 0',
      },
    },
  },
  noData: {
    backgroundColor: '#E0E0E0',
    padding: '14px 10.5px',
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#00000099',
    borderRadius: 4,
  },
});

export const EditHotspots = ({ data, onChange }) => {
  const classes = useStyles();
  const t = useModelViewerTranslation();
  const dispatch = useModelViewerDispatch();
  const { activeHotspot } = useModelViewerState();
  const [expanded, setExpanded] = useState(undefined);
  const [hotspotsInternal, setHotspotsInternal] = useState([]);

  useEffect(() => {
    if (data.hotspots) {
      setHotspotsInternal(data.hotspots.sort((a, b) => a.order - b.order));
    }
  }, [data.hotspots]);

  useEffect(() => {
    setExpanded(activeHotspot);
  }, [activeHotspot]);

  const moveHandler = useCallback(
    (dragIndex, hoverIndex) => {
      const dragged = hotspotsInternal[dragIndex];
      const hovered = hotspotsInternal[hoverIndex];
      const updatedHotspots = hotspotsInternal.reduce((acc, q) => {
        if (q.order === dragged.order) {
          return [
            ...acc,
            {
              ...q,
              order: hovered.order,
              modified: true,
            },
          ];
        } else if (q.order === hovered.order) {
          return [
            ...acc,
            {
              ...q,
              order: dragged.order,
              modified: true,
            },
          ];
        } else {
          return [...acc, q];
        }
      }, []);

      setHotspotsInternal(updatedHotspots.sort((a, b) => a.order - b.order));
    },
    [hotspotsInternal]
  );

  const dropHandler = () => {
    const original = data.hotspots.sort((a, b) => a.order - b.order);
    const modified = JSON.stringify(hotspotsInternal) !== JSON.stringify(original);

    if (modified) {
      onChange({
        ...data,
        hotspots: hotspotsInternal.map((hotspot, index) => ({
          ...hotspot,
          id: index + 1,
          order: index + 1,
        })),
      });
    }
  };

  const resetHandler = () => {
    setHotspotsInternal(data.hotspots.sort((a, b) => a.order - b.order));
  };

  const handleChange = id => (event, isExpanded) => {
    setExpanded(isExpanded ? id : 0);

    dispatch({
      type: SET_ACTIVE_HOTSPOT,
      activeHotspot: id,
    });
  };

  const renderHotspot = useCallback(
    (hotspot, idx) => {
      return (
        <EditHotspot
          index={idx}
          moveCard={moveHandler}
          dropCard={dropHandler}
          resetCards={resetHandler}
          totalHotspots={hotspotsInternal.length}
          key={hotspot.id}
          hotspot={hotspot}
          data={data}
          expanded={expanded}
          onExpandChange={handleChange}
        />
      );
    },
    [hotspotsInternal, expanded]
  );

  return (
    <div className={classes.list}>
      {hotspotsInternal.length > 0 ? (
        hotspotsInternal.map((hotspot, idx) => renderHotspot(hotspot, idx))
      ) : (
        <div className={classes.noData}>
          {t(
            'no_hotspots_text',
            'Ingen infopunkter er lagt til enda. Dobbelklikk på modellen eller bruk knappen for å opprette infopunkter'
          )}
        </div>
      )}
    </div>
  );
};
