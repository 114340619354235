import {
    SET_HELP_DIALOG_OPEN,
    useModelViewerDispatch,
    useModelViewerState,
    useModelViewerTranslation
} from "../App/ModelViewerContext";
import {Dialog, DialogContent, IconButton, Stack, useMediaQuery, useTheme} from "@mui/material";
import {Close} from "@mui/icons-material";
import {Instructions} from "./Instructions";
import {Instruction} from "./Instruction";
import {InstructionText} from "./InstructionText";

export const HelpDialog = () => {
    const t = useModelViewerTranslation()
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
    const {
        helpDialogOpen,
    } = useModelViewerState();
    const dispatch = useModelViewerDispatch();

    const handleClose = () => dispatch({type: SET_HELP_DIALOG_OPEN, helpDialogOpen: false});

    return (
        <Dialog
            open={helpDialogOpen}
            maxWidth={"md"}
            onClose={handleClose}
            fullWidth={true}
            fullScreen={fullScreen}
        >
            <DialogContent>
                <Stack
                    direction={"row"}
                    justifyContent={"flex-end"}
                >
                    <IconButton
                        onClick={handleClose}
                    >
                        <Close
                            fontSize={"large"}
                        />
                    </IconButton>
                </Stack>
                <Instructions>
                    <Instruction>
                        <svg width="156" height="78" viewBox="0 0 156 78" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M122.117 21.3207C121.011 21.192 119.914 21.6289 119.199 22.4833C118.484 23.3377 118.246 24.4942 118.569 25.5612C118.89 26.6275 119.727 27.4609 120.795 27.7778C139.981 31.747 148.712 37.7544 148.712 41.6447C149.242 48.0485 127.065 55.3523 111.268 57.1783H111.267C111.31 56.8623 111.31 56.5419 111.267 56.2258V21.4012C111.257 20.2334 110.661 19.1495 109.68 18.5166L79.5122 1.10431C79.0123 0.805792 78.4407 0.648193 77.8585 0.648193C77.2763 0.648193 76.7047 0.805774 76.2048 1.10431L46.1694 18.5166C45.1784 19.147 44.5647 20.2272 44.5288 21.4012V56.2258C44.5279 56.5672 44.5814 56.9069 44.6873 57.2317C14.4143 52.3889 -7.78839 42.4648 19.9185 32.0394L17.0611 41.7777C16.7984 42.7363 16.9762 43.7623 17.5478 44.5755C18.1195 45.3888 19.0238 45.9053 20.0139 45.9832C21.0049 46.0611 21.9793 45.6934 22.6708 44.9799L39.7924 26.2702C40.5619 25.4595 40.8526 24.3057 40.5602 23.2272C40.2827 22.1399 39.4773 21.2645 38.4163 20.8985L14.3602 12.9591C13.4059 12.6615 12.3685 12.8077 11.5334 13.3574C10.6983 13.9072 10.1546 14.8028 10.0513 15.7973C9.94715 16.7918 10.2956 17.7802 10.9994 18.4902L18.3032 25.5822C6.63284 29.843 0.519531 35.3468 0.519531 41.6456C0.520407 54.6923 26.3484 62.6308 56.7558 65.2782L76.3384 76.5783C76.8348 76.8716 77.4021 77.0257 77.979 77.0257C78.5559 77.0257 79.1223 76.8716 79.6195 76.5783L99.0962 65.2782C114.974 63.6902 153.849 59.3244 155.252 41.6994C155.331 29.9239 134.531 23.8633 122.119 21.3228L122.117 21.3207ZM74.6171 67.9224L71.2825 66.0175H74.6171V67.9224ZM74.6171 59.4544C69.0423 59.4544 63.7495 59.225 58.7393 58.7663L51.0381 54.3208V27.1159L74.59 40.7175L74.6171 59.4544ZM77.9245 35.0016L54.3727 21.4L77.9245 7.77048L101.476 21.3991L77.9245 35.0016ZM81.2329 67.9213V66.0689H84.5674L81.2329 67.9213ZM104.785 54.3197L97.1631 58.739C91.8703 59.1356 86.5784 59.3737 81.2853 59.4534V40.7172L104.837 27.1157L104.785 54.3197Z"
                                fill="black"/>
                        </svg>
                        <InstructionText>
                            {t('help.clickAndDrag', "Trykk og dra for å rotere gjenstanden")}
                        </InstructionText>
                    </Instruction>
                    <Instruction>
                        <svg width="123" height="104" viewBox="0 0 123 104" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M3.56637 20.5589C4.31097 20.5589 5.02424 20.264 5.5505 19.7377C6.07677 19.2115 6.37164 18.4982 6.37164 17.7536V10.5009L22.9307 27.4491C24.0284 28.5277 25.7908 28.5199 26.8788 27.4317C27.967 26.3435 27.9748 24.5811 26.8962 23.4835L10.3372 6.53533H17.5899C19.1391 6.53533 20.3953 5.27927 20.3953 3.72999C20.3953 2.18166 19.1392 0.925537 17.5899 0.925537H3.56611C3.53044 0.925537 3.50087 0.944674 3.4652 0.945544C3.13205 0.951633 2.80237 1.01687 2.49271 1.13952L2.48575 1.143C2.45879 1.1543 2.43878 1.17605 2.41181 1.18736C2.13954 1.31262 1.88989 1.4805 1.67244 1.68579C1.6046 1.75364 1.54023 1.82496 1.47934 1.89977C1.32363 2.07722 1.19141 2.27294 1.0853 2.48431C1.03833 2.57043 0.996578 2.65915 0.959175 2.75048C0.834787 3.06275 0.767807 3.39417 0.761719 3.72992V17.7537C0.761719 18.4983 1.05659 19.2116 1.58286 19.7379C2.10912 20.2641 2.82239 20.559 3.5661 20.559L3.56637 20.5589Z"
                                fill="black"/>
                            <path
                                d="M119.204 20.5589C118.459 20.5589 117.746 20.264 117.219 19.7377C116.693 19.2115 116.398 18.4982 116.398 17.7536V10.5009L99.8392 27.4491C98.7415 28.5277 96.9791 28.5199 95.8911 27.4317C94.8029 26.3435 94.7951 24.5811 95.8737 23.4835L112.433 6.53533H105.18C103.631 6.53533 102.375 5.27927 102.375 3.72999C102.375 2.18166 103.631 0.925537 105.18 0.925537H119.204C119.239 0.925537 119.269 0.944674 119.305 0.945544C119.638 0.951633 119.968 1.01687 120.277 1.13952L120.284 1.143C120.311 1.1543 120.331 1.17605 120.358 1.18736C120.63 1.31262 120.88 1.4805 121.097 1.68579C121.165 1.75364 121.23 1.82496 121.291 1.89977C121.446 2.07722 121.578 2.27294 121.685 2.48431C121.732 2.57043 121.773 2.65915 121.811 2.75048C121.935 3.06275 122.002 3.39417 122.008 3.72992V17.7537C122.008 18.4983 121.713 19.2116 121.187 19.7379C120.661 20.2641 119.948 20.559 119.204 20.559L119.204 20.5589Z"
                                fill="black"/>
                            <path
                                d="M119.204 83.5666C118.459 83.5666 117.746 83.8615 117.219 84.3878C116.693 84.914 116.398 85.6273 116.398 86.3719V93.6247L99.8392 76.6764C98.7415 75.5978 96.9791 75.6057 95.8911 76.6938C94.8029 77.782 94.7951 79.5444 95.8737 80.642L112.433 97.5902H105.18C103.631 97.5902 102.375 98.8462 102.375 100.396C102.375 101.944 103.631 103.2 105.18 103.2H119.204C119.239 103.2 119.269 103.181 119.305 103.18C119.638 103.174 119.968 103.109 120.277 102.986L120.284 102.983C120.311 102.971 120.331 102.949 120.358 102.938C120.63 102.813 120.88 102.645 121.097 102.44C121.165 102.372 121.23 102.301 121.291 102.226C121.446 102.048 121.578 101.853 121.685 101.641C121.732 101.555 121.773 101.466 121.811 101.375C121.935 101.063 122.002 100.731 122.008 100.396V86.3718C122.008 85.6272 121.713 84.9139 121.187 84.3876C120.661 83.8614 119.948 83.5665 119.204 83.5665L119.204 83.5666Z"
                                fill="black"/>
                            <path
                                d="M3.56637 83.5666C4.31097 83.5666 5.02424 83.8615 5.5505 84.3878C6.07677 84.914 6.37164 85.6273 6.37164 86.3719V93.6247L22.9307 76.6764C24.0284 75.5978 25.7908 75.6057 26.8788 76.6938C27.967 77.782 27.9748 79.5444 26.8962 80.642L10.3372 97.5902H17.5899C19.1391 97.5902 20.3953 98.8462 20.3953 100.396C20.3953 101.944 19.1392 103.2 17.5899 103.2H3.56611C3.53044 103.2 3.50087 103.181 3.4652 103.18C3.13205 103.174 2.80237 103.109 2.49271 102.986L2.48575 102.983C2.45879 102.971 2.43878 102.949 2.41181 102.938C2.13954 102.813 1.88989 102.645 1.67244 102.44C1.6046 102.372 1.54023 102.301 1.47934 102.226C1.32363 102.048 1.19141 101.853 1.0853 101.641C1.03833 101.555 0.996578 101.466 0.959175 101.375C0.834787 101.063 0.767807 100.731 0.761719 100.396V86.3718C0.761719 85.6272 1.05659 84.9139 1.58286 84.3876C2.10912 83.8614 2.82239 83.5665 3.5661 83.5665L3.56637 83.5666Z"
                                fill="black"/>
                            <path
                                d="M31.2719 35.4094L61.3852 18.1352L91.4985 35.4094V69.9207L61.3852 87.195L31.2719 69.9207V35.4094Z"
                                stroke="black" strokeWidth="6.68044"/>
                            <path
                                d="M92.1954 35.4219L62.715 52.3331C61.8915 52.8055 60.8792 52.8055 60.0557 52.3331L30.5752 35.4219"
                                stroke="black" strokeWidth="6.68044"/>
                            <path d="M61.4512 53.1375V86.8641" stroke="black" strokeWidth="6.68044"/>
                        </svg>
                        <InstructionText>
                            {t('help.scrollOrPinch', "Scroll eller knip for å zoome")}
                        </InstructionText>
                    </Instruction>
                    <Instruction>
                        <svg width="133" height="108" viewBox="0 0 133 108" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M67.9531 32.1216C67.1296 31.6492 66.1173 31.6492 65.2938 32.1216L34.5125 49.7791C33.6821 50.2554 33.1699 51.1396 33.1699 52.097V87.3821C33.1699 88.3395 33.6821 89.2237 34.5125 89.7L65.2938 107.357C66.1173 107.83 67.1296 107.83 67.9531 107.357L98.7345 89.7C99.5649 89.2237 100.077 88.3395 100.077 87.3821V52.097C100.077 51.1396 99.5649 50.2554 98.7345 49.7791L67.9531 32.1216ZM66.6235 39.0604L43.3898 52.3882L66.8101 65.8231L90.0437 52.4953L66.6235 39.0604ZM39.8504 85.0605V58.0594L63.3491 71.5393V98.5404L39.8504 85.0605ZM70.0296 98.4648L93.3966 85.0605V58.2735L70.0296 71.6778V98.4648Z"
                                  fill="black"/>
                            <path
                                d="M77.9398 14.9955C77.4076 15.5162 76.6915 15.8041 75.9473 15.7959C75.2031 15.7878 74.4935 15.4841 73.9728 14.9518L68.9011 9.76719L69.0036 25.4625C68.9731 27.0011 67.7078 28.2279 66.1691 28.2109C64.6302 28.1939 63.3923 26.9396 63.3958 25.4007L63.2934 9.70544L58.1088 14.7771C57.0013 15.8604 55.225 15.841 54.1416 14.7334C53.0589 13.6266 53.0784 11.8504 54.1859 10.767L64.2108 0.960422C64.2363 0.935482 64.2709 0.92848 64.297 0.904164C64.5394 0.67555 64.8207 0.491644 65.1278 0.362779L65.1352 0.360402C65.1624 0.34963 65.1919 0.351182 65.2191 0.34041C65.5013 0.239556 65.7972 0.184993 66.0962 0.179688C66.1921 0.180744 66.288 0.186721 66.3838 0.197618C66.6192 0.215588 66.8506 0.263038 67.0743 0.339936C67.1681 0.368648 67.2599 0.402877 67.3505 0.442009C67.6578 0.578251 67.9375 0.768325 68.1766 1.00408L77.9832 11.029C78.5038 11.5613 78.7918 12.2773 78.7836 13.0216C78.7754 13.7658 78.4715 14.4754 77.9398 14.9955Z"
                                fill="black"/>
                            <path
                                d="M14.8076 57.8426C15.3286 58.3745 15.6171 59.0904 15.6094 59.8346C15.6018 60.5788 15.2986 61.2886 14.7667 61.8096L9.58556 66.8849L25.2803 66.8542C26.819 66.8836 28.0467 68.148 28.0307 69.6868C28.0148 71.2256 26.7613 72.4644 25.2225 72.462L9.52769 72.4927L14.603 77.6738C15.687 78.7805 15.6688 80.5568 14.562 81.641C13.456 82.7244 11.6797 82.7062 10.5955 81.5994L0.782039 71.5813C0.757082 71.5558 0.750057 71.5213 0.725722 71.4952C0.49694 71.253 0.312838 70.9718 0.183762 70.6648L0.181378 70.6573C0.170587 70.6302 0.172121 70.6007 0.16133 70.5735C0.0602812 70.2913 0.00551269 69.9955 0 69.6965C0.000989999 69.6006 0.00690002 69.5047 0.0177307 69.4088C0.035538 69.1734 0.0828264 68.942 0.15957 68.7183C0.188217 68.6245 0.222384 68.5326 0.261454 68.4419C0.397482 68.1346 0.587362 67.8548 0.822956 67.6155L10.8411 57.802C11.373 57.281 12.0889 56.9925 12.8331 57.0001C13.5773 57.0078 14.2872 57.3113 14.8076 57.8426Z"
                                fill="black"/>
                            <path
                                d="M117.223 81.5991C116.702 81.0672 116.414 80.3513 116.421 79.6071C116.429 78.8629 116.732 78.1531 117.264 77.632L122.445 72.5568L106.751 72.5875C105.212 72.5581 103.984 71.2936 104 69.7549C104.016 68.2161 105.27 66.9772 106.808 66.9797L122.503 66.949L117.428 61.7679C116.344 60.6612 116.362 58.8849 117.469 57.8007C118.575 56.7172 120.351 56.7355 121.435 57.8423L131.249 67.8604C131.274 67.8859 131.281 67.9204 131.305 67.9465C131.534 68.1887 131.718 68.4699 131.847 68.7769L131.849 68.7843C131.86 68.8115 131.859 68.841 131.87 68.8682C131.971 69.1503 132.025 69.4462 132.031 69.7452C132.03 69.8411 132.024 69.937 132.013 70.0328C131.995 70.2682 131.948 70.4997 131.871 70.7234C131.843 70.8172 131.808 70.9091 131.769 70.9997C131.633 71.3071 131.443 71.5869 131.208 71.8262L121.19 81.6397C120.658 82.1607 119.942 82.4492 119.198 82.4415C118.454 82.4338 117.744 82.1304 117.223 81.5991Z"
                                fill="black"/>
                        </svg>
                        <InstructionText>
                            {t('help.moveCamera', "Bruk to fingre eller CTRL for å flytte")}
                        </InstructionText>
                    </Instruction>
                </Instructions>
            </DialogContent>
        </Dialog>
    )
}
