import {ModelViewer} from '../Canvas/ModelViewer';
import {Carousel} from './Carousel';
import {ModelInfo} from './ModelInfo';
import {createUseStyles} from 'react-jss';
import {
  HOTSPOT_CLOSED,
  SET_SHOW_MODEL_INFO,
  useModelViewerDispatch,
  useModelViewerProps,
  useModelViewerState
} from '../App/ModelViewerContext';
import {CloseButton} from '../components/CloseButton';
import {ToggleInfoButton} from "./ToggleInfoButton";

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    position: 'relative',
  },
  main: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  toggle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    left: 18,
    bottom: 18,
    gap: 5,
    fontSize: 15,
    cursor: 'pointer',
    '& svg': {
      width: 18,
      height: 18,
    },
  },
});

export const Display = ({ isMobile, onClose }) => {
  const { data, onGroupItemChange } = useModelViewerProps();
  const { group, model } = data;
  const { showModelInfo } = useModelViewerState();
  const dispatch = useModelViewerDispatch();
  const classes = useStyles();

  const handleModelInfoToggle = () => {
    dispatch({ type: SET_SHOW_MODEL_INFO, showModelInfo: !showModelInfo });
  };

  const handleGroupItemChange = (index) => {
    dispatch({type: HOTSPOT_CLOSED})
    onGroupItemChange(index);
  };

  return (
    <div className={classes.container}>
      <CloseButton
          onClick={onClose}
          isMobile={isMobile}
      />
      {model.info && (
        <ModelInfo
          data={model}
          open={showModelInfo}
          isMobile={isMobile}
          onToggle={handleModelInfoToggle}
        />
      )}
      <div className={classes.main}>
        <ModelViewer isMobile={isMobile} />
        {model.info && (
            <ToggleInfoButton />
        )}
        {group && <Carousel data={group} onChange={handleGroupItemChange} />}
      </div>
    </div>
  );
};
