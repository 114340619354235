import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { DemoLinks } from './DemoLinks';
import { DemoModelViewer } from './DemoModelViewer';
import { DemoModelViewerAdmin } from './DemoModelViewerAdmin';

export const Demo = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DemoLinks />} />
        <Route path="model/:id" element={<DemoModelViewer />} />
        <Route path="admin/:id" element={<DemoModelViewerAdmin />} />
      </Routes>
    </BrowserRouter>
  );
};
