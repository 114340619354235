import {createContext, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {convertToThreeJsTypes} from '../helpers/helpers';
import {Euler} from 'three';
import {create} from 'zustand';

const ModelViewerPropsContext = createContext(undefined);
const ModelViewerTranslateContext = createContext(undefined);

export const ModelViewerProvider = ({
                                        data,
                                        onChange,
                                        onMediaSelect,
                                        onGroupItemChange,
                                        adminMode,
                                        appReady,
                                        children,
                                    }) => {
    const {t, ready} = useTranslation('vm-admin.modelViewer', {
        useSuspense: false,
    });
    if (!ready || !appReady) {
        return null;
    } else {
        return (
            <ModelViewerPropsContext.Provider
                value={{
                    onChange,
                    data: convertToThreeJsTypes(JSON.parse(JSON.stringify(data))),
                    onMediaSelect,
                    onGroupItemChange,
                    adminMode,
                }}
            >
                <ModelViewerTranslateContext.Provider value={t}>
                    {children}
                </ModelViewerTranslateContext.Provider>
            </ModelViewerPropsContext.Provider>
        );
    }
};

export const APP_SETUP = 'appSetup';
export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
export const SET_CAMERA_VALUES = 'SET_CAMERA_VALUES';
export const SET_IS_ANIMATING = 'SET_IS_ANIMATING';
export const SET_SHOW_ANNOTATIONS = 'SET_SHOW_ANNOTATIONS';
export const SET_HELP_DIALOG_OPEN = 'SET_HELP_DIALOG_OPEN';
export const SET_SHOW_GIZMO = 'SET_SHOW_GIZMO';
export const SET_CAMERA_CONTROLS_ENABLED = 'SET_CAMERA_CONTROLS_ENABLED';
export const SET_ACTIVE_HOTSPOT = 'SET_ACTIVE_HOTSPOT';
export const HOTSPOT_CLOSED = 'hotspotClosed'
export const SET_ADD_HOTSPOT_MODE = 'SET_ADD_HOTSPOT_MODE';
export const SET_SHOW_MODEL_INFO = 'SET_SHOW_MODEL_INFO';
export const SET_CURRENT_CAMERA_ROTATION = 'SET_CURRENT_CAMERA_ROTATION';
export const SET_PREVIOUS_CAMERA_ROTATION = 'SET_PREVIOUS_CAMERA_ROTATION';
export const MODEL_SCALED = 'modelScaled'
export const STEPPER_NEXT_CLICKED = 'stepperNextClicked'
export const STEPPER_PREVIOUS_CLICKED = 'stepperPreviousClicked'
export const RESET_CAMERA = 'resetCamera'

const initialState = {
    appReady: false,
    cameraValues: null,
    isAnimating: false,
    showToolbar: true,
    showAnnotations: true,
    showGizmo: false,
    enablePan: true,
    enableZoom: true,
    helpDialogOpen: false,
    cameraControlsEnabled: true,
    activeHotspot: 0,
    addHotspotMode: false,
    showModelInfo: false,
    currentCameraRotation: null,
    previousCameraRotation: new Euler(0, 0, 1),
    scaleFactor: 1,
    updateCamera: false,
    resetCameraCount: 0
};

const reducer = (state, action) => {
    // console.debug({action})
    switch (action.type) {
        case SET_INITIAL_STATE:
            return {
                ...state,
                showToolbar: action.showToolbar,
                showAnnotations: action.showAnnotations,
                showGizmo: action.showGizmo,
                enablePan: action.enablePan,
                enableZoom: action.enableZoom,
                showModelInfo: action.showModelInfo,
            };
        case APP_SETUP:
            return {
                ...state,
                appReady: true,
                dmsIndex: action.dmsIndex,
            };
        case MODEL_SCALED:
            return {
                ...state,
                scaleFactor: action.scaleFactor
            }
        case SET_CAMERA_VALUES:
            return {
                ...state,
                cameraValues: action.cameraValues,
            };
        case SET_IS_ANIMATING:
            return {
                ...state,
                isAnimating: action.isAnimating,
            };
        case SET_SHOW_ANNOTATIONS:
            return {
                ...state,
                showAnnotations: action.showAnnotations,
            };
        case SET_SHOW_GIZMO:
            return {
                ...state,
                showGizmo: action.showGizmo,
            };
        case SET_HELP_DIALOG_OPEN:
            return {
                ...state,
                helpDialogOpen: action.helpDialogOpen,
            };
        case SET_CAMERA_CONTROLS_ENABLED:
            return {
                ...state,
                cameraControlsEnabled: action.cameraControlsEnabled,
            };
        case SET_ACTIVE_HOTSPOT:
            return {
                ...state,
                activeHotspot: action.activeHotspot,
                updateCamera: false
            };
        case HOTSPOT_CLOSED:
            return {
                ...state,
                activeHotspot: 0,
                updateCamera: false
            }
        case SET_ADD_HOTSPOT_MODE:
            return {
                ...state,
                addHotspotMode: action.addHotspotMode,
            };
        case SET_SHOW_MODEL_INFO:
            return {
                ...state,
                showModelInfo: action.showModelInfo,
            };
        case SET_CURRENT_CAMERA_ROTATION:
            return {
                ...state,
                currentCameraRotation: action.currentCameraRotation,
            };
        case SET_PREVIOUS_CAMERA_ROTATION:
            return {
                ...state,
                previousCameraRotation: action.previousCameraRotation,
            };
        case STEPPER_NEXT_CLICKED:
            return {
                ...state,
                activeHotspot: state.activeHotspot + 1,
                updateCamera: true
            }
        case STEPPER_PREVIOUS_CLICKED:
            return {
                ...state,
                activeHotspot: state.activeHotspot - 1,
                updateCamera: true
            }
        case RESET_CAMERA:
            return {
                ...state,
                resetCameraCount: state.resetCameraCount + 1
            }
        default:
            return undefined;
    }
};

export const useModelViewerProps = () => {
    const context = useContext(ModelViewerPropsContext);

    if (undefined === context) {
        throw new Error(
            'useModelViewerProps must be used within a ModelViewerProvider'
        );
    } else {
        return context;
    }
};

export const useModelViewerTranslation = () => {
    const context = useContext(ModelViewerTranslateContext);

    if (undefined === context) {
        throw new Error(
            'useModelViewerTranslation must be used within a ModelViewerProvider'
        );
    } else {
        return context;
    }
};

const useStore = create(set => {
    return {
        ...initialState,
        dispatch: args => set(state => reducer(state, args)),
    };
});

export const useModelViewerState = () => useStore();

export const useModelViewerDispatch = () => useStore(state => state.dispatch);
