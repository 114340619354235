import {Typography} from "@mui/material";

export const InstructionText = ({children}) => {
    return (
        <Typography
            align={"center"}
            sx={{
                fontSize: "20px",
                width: 175
            }}
        >
            {children}
        </Typography>
    )
}