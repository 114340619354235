export const updateModelRotation = (data, rotation) => {
  return {
    ...data,
    model: {
      ...data.model,
      rotation: {
        ...data.model.rotation,
        ...rotation,
      },
    },
  };
};

export const updateModelInfo = (data, modelInfo) => {
  return {
    ...data,
    model: {
      ...data.model,
      info: {
        ...data.model.info,
        ...modelInfo,
      },
    },
  };
};

