import {
  SET_INITIAL_STATE,
  useModelViewerDispatch,
} from './ModelViewerContext';
import { useEffect } from 'react';

export const useInitializeApp = ({
  showToolbar,
  showAnnotations,
  showGizmo,
  enablePan,
  enableZoom,
  showModelInfo,
}) => {
  const dispatch = useModelViewerDispatch();

  useEffect(() => {
    dispatch({
      type: SET_INITIAL_STATE,
      showToolbar,
      showAnnotations,
      showGizmo,
      enablePan,
      enableZoom,
      showModelInfo,
    });
  }, [showToolbar, showAnnotations, showGizmo, enablePan, enableZoom, showModelInfo]);
};
