import {Box, IconButton, Paper, Popper, Stack, Typography} from "@mui/material";
import {Html} from "@react-three/drei";
import {useThree, useFrame} from "@react-three/fiber";
import {useCanvasWidth} from "../Canvas/useCanvasWidth";
import {useState} from "react";
import {Vector3} from 'three';
import {HotspotContentLink} from './HotspotContentLink'
import {Close} from "@mui/icons-material";
import {HOTSPOT_CLOSED, useModelViewerDispatch} from "../App/ModelViewerContext";

const generateGetBoundingClientRect = (x = 0, y = 0) => () => ({
    width: 0,
    height: 0,
    top: y,
    right: x,
    bottom: y,
    left: x,
});

export const HotspotContent = ({open, anchorPosition, title, description, links, rotationContainer}) => {
    const id = open ? "hotspot-popper" : undefined
    const {camera} = useThree()
    const {
        canvasWidth,
        canvasLeftOffset
    } = useCanvasWidth()
    const halfWidth = canvasWidth / 2
    const halfHeight = window.innerHeight / 2
    const [position, setPosition] = useState(null)
    const dispatch = useModelViewerDispatch()

    useFrame(
        () => {
            if (anchorPosition && open) {
                const clonedAnchorPosition = rotationContainer.worldToLocal(new Vector3(...anchorPosition))
                clonedAnchorPosition.project(camera)
                setPosition(clonedAnchorPosition)
            }
        }
    )

    if (!open || !position) {
        return null
    }

    const closeHandler = () => {
        dispatch({
            type: HOTSPOT_CLOSED
        })
    }


    const xPosition = (position.x * halfWidth) + halfWidth;
    const placeRight = xPosition > halfWidth

    const virtualElement = {
        getBoundingClientRect: generateGetBoundingClientRect(
            placeRight ? xPosition + 50 + canvasLeftOffset : xPosition - 50 + canvasLeftOffset,
            -(position.y * halfHeight) + halfHeight
        ),
    }

    return (
        <Html
        >
            <Popper
                id={id}
                open={open}
                anchorEl={virtualElement}
                placement={placeRight ? "right" : "left"}
                sx={{
                    maxWidth: '400px',
                    minWidth: '200px'
                }}
            >
                <Paper
                    sx={{
                        p: 4,
                    }}
                >
                    <IconButton
                        onClick={closeHandler}
                        sx={{
                            position: "fixed",
                            top: 4,
                            right: 4
                        }}
                    >
                        <Close />
                    </IconButton>
                    <Stack
                        spacing={2}
                    >
                        <Typography
                            variant={"h5"}
                        >
                            {title}
                        </Typography>
                        <Typography>
                            {description}
                        </Typography>
                        <Box>
                            {links?.map(({url, text}, idx) => (
                                <HotspotContentLink
                                    url={url}
                                    text={text}
                                    key={idx}
                                />
                            ))}
                        </Box>
                    </Stack>
                </Paper>
            </Popper>
        </Html>
    )
}