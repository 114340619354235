import {useModelViewerProps} from "../App/ModelViewerContext";
import {ImagePlane} from "./ImagePlane";

export const ImageBack = ({onClick}) => {
    const {
        data,
    } = useModelViewerProps()
    const {model} = data;
    const {
        backsideUrl,
        backsideDmsId,
    } = model


    if (backsideUrl || backsideDmsId) {
        return (
            <ImagePlane
                rotation={[0, 0, 0]}
                onClick={onClick}
                dmsId={backsideDmsId}
                url={backsideUrl}
            />
        )
    } else {
        return null
    }
}