import {useModelViewerProps} from "../App/ModelViewerContext";
import {useTexture} from "@react-three/drei";
import {getDmsUrl} from "@ekultur/dms-url-generator";
import {useEffect} from "react";
import {Vector3} from "three";
import {useThree} from "@react-three/fiber";

export const ImageFront = ({onClick, onScale}) => {
    const {
        data,
    } = useModelViewerProps()
    const {model} = data;
    const {
        url,
        dmsId,
    } = model

    const {
        camera,
    } = useThree();

    const texture = useTexture(dmsId ? `https://ems.dimu.org/image/${dmsId}?dimension=2048` : url);

    useEffect(() => {
        const fov = camera.fov * Math.PI / 180;
        const h = 2 * Math.tan(fov / 2);
        const w = h * camera.aspect;
        const imageAspect = texture.image.width / texture.image.height;
        const scale = Math.min(h, w / imageAspect) * 0.7;
        onScale(new Vector3(scale, scale, scale));
    }, [dmsId, url]);

    return (
        <mesh
            rotation={[0, Math.PI, 0]}
            onClick={onClick}
        >
            <planeGeometry
                attach="geometry"
                args={[texture.image.width / texture.image.height, 1]}
            />
            <meshStandardMaterial
                attach="material"
                map={texture}
            />
        </mesh>
    )
}