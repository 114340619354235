import { cloneElement, useState } from 'react';
import { useGesture } from '@use-gesture/react';
import { PlaybackButton } from './PlaybackButton';

export const AudioController = ({ children }) => {
  const [started, setStarted] = useState(false);
  const [paused, setPaused] = useState(true);
  const [hover, setHover] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleEnded = (loop) => {
    !loop && setPaused(true) && setStarted(false);
    setProgress(0);
  };

  const handleProgressChange = (val) => {
    setProgress(val);
  };

  const handleDrag = ({ tap }) => {
    if (tap) {
      setPaused(!paused);
      !started && setStarted(true);
    }
  };

  const handleHover = ({ first, last }) => {
    first && setHover(true);
    last && setHover(false);
  };

  const useGestures = () => {
    return useGesture(
      {
        onDrag: handleDrag,
        onHover: handleHover,
      },
      { drag: { filterTaps: true }},
    )();
  };

  return (
    <>
      <div
        style={{ touchAction: 'none', width: 'fit-content' }}
        {...useGestures()}
      >
        <PlaybackButton
          started={started}
          paused={paused}
          hover={hover}
          progress={progress}
        />
      </div>
      {children && cloneElement(children, {
        paused,
        onProgressChange: handleProgressChange,
        onEnded: handleEnded,
      })}
    </>
  );
};
