import {useModelViewerProps, useModelViewerState} from "../App/ModelViewerContext";
import {useEffect, useState} from "react";

export const useLookAt = ({rotationContainer}) => {
    const {
        updateCamera,
        activeHotspot,
        resetCameraCount,
        scaleFactor
    } = useModelViewerState()

    const [cameraTo, setCameraTo] = useState({
        position: null,
        target: null
    })
    const {data} = useModelViewerProps()
    const {hotspots} = data

    useEffect(
        () => {
            if(updateCamera) {
                const hotspot = hotspots?.find(h => h.id === activeHotspot)
                if(hotspot) {
                    const target = rotationContainer.localToWorld(hotspot.position.clone())
                    const position = target.clone().normalize().multiplyScalar(hotspot.zoom)
                    setCameraTo({
                        position: [position.x, position.y, position.z],
                        target: [target.x, target.y, target.z],
                    })
                }
            }
        },
        [updateCamera, activeHotspot]
    )

    useEffect(
        () => {
            if(0 < resetCameraCount) {
                setCameraTo({
                    position: [0, 0, -scaleFactor * 0.15],
                    target: [0,0,0]
                })
            }
        }, [resetCameraCount]
    )

    return cameraTo
}