import { DeleteOutline } from '@mui/icons-material';
import { TextField } from '../components/TextField';
import { createUseStyles } from 'react-jss';
import { useModelViewerTranslation } from '../App/ModelViewerContext';

const useStyles = createUseStyles({
  link: {
    display: 'flex',
    flexDirection: 'column',
  },
  linkHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  linkId: {
    fontSize: 16,
    lineHeight: '25.6px',
  },
  linkDelete: {
    color: 'rgba(0, 0, 0, 0.6)',
    cursor: 'pointer',
    '&.MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },
});

export const Links = ({
  links = [],
  onDelete,
  onUpdate,
}) => {
  const classes = useStyles();
  const t = useModelViewerTranslation();

  return (
    <>
      {links.map(({ text, url }, i) => (
        <div key={`Link-${i}`} className={classes.link}>
          <div className={classes.linkHeader}>
            <span className={classes.linkId}>{`${t('linkIdText', 'Lenke')} ${
              i + 1
            }`}</span>
            <DeleteOutline
              className={classes.linkDelete}
              onClick={() => onDelete(i)}
            />
          </div>
          <TextField
            id="link-url"
            label={t('linkUrlLabel', 'Lenke URL')}
            variant="filled"
            sx={{ margin: '10px 0' }}
            value={url}
            onChange={value => onUpdate('url', value, i)}
          />
          <TextField
            id="link-text"
            label={t('linkTextLabel', 'Lenke tekst')}
            variant="filled"
            sx={{ margin: '10px 0' }}
            value={text}
            onChange={value => onUpdate('text', value, i)}
          />
        </div>
      ))}
    </>
  );
};
