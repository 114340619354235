import {Stack, Typography} from "@mui/material";
import {ShowHotspotsToggle} from "./ShowHotspotsToggle";
import {ShowGizmoToggle} from "./ShowGizmoToggle";
import {ResetCameraButton} from "./ResetCameraButton";

export const SettingsContent = ({hasHotspots, onClose}) => {
    return (
        <Stack
            sx={{
                padding: 2
            }}
        >
            <Typography
                variant={"h6"}
            >
                Innstillinger
            </Typography>
            <ShowHotspotsToggle
                hasHotspots={hasHotspots}
                onClick={onClose}
            />
            <ShowGizmoToggle
                onClick={onClose}
            />
            <ResetCameraButton
                onClick={onClose}
            />
        </Stack>
    )
}