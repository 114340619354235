import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three/addons/loaders/GLTFLoader";
import {DRACOLoader} from "three/addons/loaders/DRACOLoader";
import {KTX2Loader} from "../helpers/KTX2Loader";
import {WebGLRenderer} from "three";
import {MeshoptDecoder} from "three/addons/libs/meshopt_decoder.module";

export const useGLTF = url => {
    return useLoader(GLTFLoader, url, (loader) => {
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.5/');
        loader.setDRACOLoader(dracoLoader);

        const ktxLoader = new KTX2Loader()
            .setTranscoderPath('https://kit-artifacts.s3.eu-west-1.amazonaws.com/basis_transcoder/')
            .detectSupport(new WebGLRenderer());
        loader.setKTX2Loader(ktxLoader);
        loader.setMeshoptDecoder(MeshoptDecoder);
    });
};