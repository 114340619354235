import { GizmoHelper, GizmoViewport } from '@react-three/drei';

export const Gizmo = ({
  xColor,
  yColor,
  zColor,
  labelColor,
  onUpdate,
  onTarget,
}) => {
  return (
    <GizmoHelper
      alignment="bottom-right"
      margin={[80, 130]}
      onUpdate={onUpdate}
      onTarget={onTarget}
    >
      <GizmoViewport
        axisColors={[xColor, yColor, zColor]}
        labelColor={labelColor}
      />
    </GizmoHelper>
  );
};
