import {ModelTypes} from "../helpers/enums";
import {Stage} from "@react-three/drei";
import {Model} from "./Model";
import {Image} from "./Image";
import {MODEL_SCALED, useModelViewerDispatch, useModelViewerProps} from "../App/ModelViewerContext";

export const ModelOnStage = () => {
    const {data} = useModelViewerProps()
    const {model} = data
    const dispatch = useModelViewerDispatch()

    const handleScale = scaleFactor => {
        dispatch({
            type: MODEL_SCALED,
            scaleFactor: scaleFactor.x
        })
    }

    const modelByType = {
        [ModelTypes.OBJECT]: (
            <Model
                onScale={handleScale}
            />
        ),
        [ModelTypes.IMAGE_SINGLE]: (
            <Image
                onScale={handleScale}
            />
        ),
        [ModelTypes.IMAGE_DOUBLE]: (
            <Image
                onScale={handleScale}
            />
        )
    }

    return (
        <Stage
            intensity={0.1}
            preset={"soft"}
            adjustCamera={true}
        >
            {modelByType[model.type]}
        </Stage>
    )
}