import {Box, Button, IconButton} from "@mui/material";
import {Close} from "@mui/icons-material";

export const CloseButton = ({
    isMobile,
    onClick,
}) => {

    const button = {
        'mobile': (
            <IconButton
                onClick={onClick}
            >
                <Close
                    fontSize={"large"}
                />
            </IconButton>
        ),
        'desktop': (
            <Button
                startIcon={<Close/>}
                onClick={onClick}
                variant={"outlined"}
                size={"large"}
            >
                Lukk
            </Button>
        )
    }

    if(onClick) {
        return (
            <Box
                sx={{
                    position: 'absolute',
                    top: 15,
                    right: 15,
                    zIndex: '999999999 !important',
                }}
            >
                {button[isMobile ? "mobile" : "desktop"]}
            </Box>
        )
    } else {
        return null
    }

}