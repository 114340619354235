import {SET_SHOW_GIZMO, useModelViewerDispatch, useModelViewerState} from "../../App/ModelViewerContext";
import {FormControlLabel, Switch} from "@mui/material";

export const ShowGizmoToggle = ({onClick}) => {
    const dispatch = useModelViewerDispatch();
    const {showGizmo} = useModelViewerState()

    const handleGizmoToggle = e => {
        e.preventDefault();
        dispatch({
            type: SET_SHOW_GIZMO,
            showGizmo: e.target.checked
        })
        onClick()
    };

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={showGizmo}
                    onChange={handleGizmoToggle}
                    inputProps={{ 'aria-label': 'Show-gizmo' }}
                />
            }
            label="Gyrograf"
        />
    )
}