import {Hotspot} from './Hotspot';
import {Select} from "@react-three/drei";
import {
    HOTSPOT_CLOSED,
    SET_ACTIVE_HOTSPOT,
    useModelViewerDispatch,
    useModelViewerProps,
    useModelViewerState
} from "../App/ModelViewerContext";
import {updateHotspot} from "./hotspot";
import {useRef} from "react";
import {useRotationContainer} from "../rotation/useRotationContainer";

export const Hotspots = ({ready=true}) => {
    const {data, onChange} = useModelViewerProps()
    const {hotspots, model} = data;
    const dispatch = useModelViewerDispatch()
    const {
        activeHotspot,
        showAnnotations
    } = useModelViewerState()
    const groupRef = useRef()
    const rotationContainer = useRotationContainer(groupRef?.current)

    const onHotspotChange = (id, changes) => {
        onChange(updateHotspot(data, id, changes));
    };


    const handleSelect = selectedMesh => {
        console.debug({selectedMesh})
        if (selectedMesh.length) {
            dispatch({
                type: SET_ACTIVE_HOTSPOT,
                activeHotspot: selectedMesh[0]?.parent?.userData.id,
            })
        } else if (activeHotspot) {
            dispatch({
                type: HOTSPOT_CLOSED
            })
        }

    }

    if (showAnnotations && ready) {
        return (
            <group
                ref={groupRef}
            >
                <Select
                    box
                    onChangePointerUp={handleSelect}
                >
                    {hotspots?.map(
                        (hotspot) =>
                            hotspot.id !== 0 && (
                                <Hotspot
                                    key={hotspot.id}
                                    id={hotspot.id}
                                    hotspot={hotspot}
                                    isSelected={hotspot.id === activeHotspot}
                                    modelRotation={model.rotation}
                                    onChange={onHotspotChange}
                                    rotationContainer={rotationContainer}
                                />
                            )
                    )}
                </Select>
            </group>
        )
    } else {
        return null
    }

};
