import SettingsIcon from "@mui/icons-material/Settings";
import {Box, IconButton, Popover} from "@mui/material";
import {useState} from "react";
import {SettingsContent} from "./SettingsContent";

export const Settings = ({data}) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? "settings-popover" : undefined

    return (
        <Box>
            <IconButton
                onClick={handleClick}
                color={"primary"}
            >
                <SettingsIcon
                    fontSize={"large"}
                />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <SettingsContent
                    hasHotspots={Boolean(data.hotspots?.length)}
                    onClose={handleClose}
                />
            </Popover>
        </Box>
    )
}